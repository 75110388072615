import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
import { useState } from 'react';
import { AlertMessage } from '@clubhouse/shared/components/AlertMessage';
import { Button } from '@clubhouse/shared/components/Button';
import { getById as getTeamById } from 'data/entity/team';
import { getByIdOrDefault as getWorkflowByIdOrDefault } from 'data/entity/workflow';
import { PAGE_NAMES, generatePathForPage, getCurrentSlug, navigateOnClick } from 'utils/navigation';
import { WorkflowStateDropdown } from '../stories/WorkflowStateDropdown';
import { Actions } from './shared/Actions';
import { MappingHeader, MappingList, MappingRow } from './shared/MappingList';
import { Para } from './shared/Para';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const MapToWorkflowState = ({
  platformLabelObj,
  goBack,
  workflowId,
  onDone,
  workflowTypes
}) => {
  const manageWorkflowUrl = generatePathForPage(PAGE_NAMES.SETTINGS_WORKFLOW, {
    slug: getCurrentSlug()
  });
  const setInitialData = (teamId, workflowStates = []) => {
    const currentWorkflowStates = getTeamById(teamId).workflow.states;
    const defaultState = currentWorkflowStates.find(w => w.type === 'unstarted');
    const sampleWorkflowState = workflowStates[0];
    const selectedDifferentWorkflowThanPreviouslySaved = sampleWorkflowState && sampleWorkflowState.target_id && currentWorkflowStates.some(i => i.id === sampleWorkflowState.target_id) ? false : true;
    return workflowStates.map(workflowState => {
      if (!workflowState.target_id || selectedDifferentWorkflowThanPreviouslySaved) {
        workflowState.target_id = defaultState.id;
      }
      return workflowState;
    });
  };
  const workflow = getWorkflowByIdOrDefault(workflowId);
  const [workflowStates, setWorkflowtypes] = useState(setInitialData(workflow.team_id, workflowTypes));
  const select = (selectedVal, index) => {
    const clone = workflowStates.slice(0);
    clone[index].target_id = selectedVal;
    setWorkflowtypes(clone);
  };
  const to = (index, initial) => _jsx(WorkflowStateDropdown, {
    teamId: workflow.team_id,
    onSelect: val => select(val, index),
    initial: initial
  });
  const isJiraWorkflow = platformLabelObj.platform === 'Jira';
  const headerLabel = isJiraWorkflow ? 'Jira Workflow State' : 'Workflow State';
  return _jsxs(_Fragment, {
    children: [isJiraWorkflow ? _jsxs(Para, {
      children: ["Map your Jira Workflow to Workflow States in ", BRAND.NAME, ". We will also apply a Label with the previous Jira Workflow State."]
    }) : null, _jsx(AlertMessage, {
      kind: AlertMessage.KIND.INFO,
      children: _jsxs("div", {
        children: ["Want to create a new Workflow State?", ' ', _jsx("a", {
          href: manageWorkflowUrl,
          onClick: navigateOnClick(manageWorkflowUrl),
          children: "Visit Settings"
        }), ' ', "to add new States, then revisit the Import menu to map your data."]
      })
    }), _jsxs(MappingList, {
      children: [_jsx(MappingHeader, {
        fromLabel: headerLabel,
        toLabel: `${BRAND.NAME} Workflow State`
      }), workflowStates.length ? workflowStates.map((w, i) => _jsx(MappingRow, {
        from: w.name,
        to: to(i, w.target_id)
      }, w.id)) : _jsx(Para, {
        children: "No Jira Workflow States found. Add an issue to your Jira Project, then reimport to continue."
      })]
    }), _jsxs(Actions, {
      children: [_jsx(Button, {
        kind: Button.KIND.SECONDARY,
        onClick: goBack,
        children: "Go Back"
      }), _jsx(Button, {
        isDisabled: !workflowStates.length,
        kind: Button.KIND.PRIMARY,
        onClick: () => onDone(workflowStates),
        children: "Next: Map Users"
      })]
    })]
  });
};