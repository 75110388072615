import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { getTeamItem } from '@clubhouse/shared/components/TeamSelect';
import { colorKeyMap } from '@clubhouse/shared/utils/teams';
const getOptionalConfigForGroupKey = {
  storyTeam: allowBulkSelect => ({
    sectionId: 'storyTeams',
    sectionTitle: 'Associated Teams',
    allowBulkSelect
  }),
  epicTeam: null,
  objectiveTeam: null,
  currentPermissionTeam: allowBulkSelect => ({
    sectionId: 'myTeams',
    sectionTitle: 'My Teams',
    allowBulkSelect
  }),
  selectedTeam: null,
  default: allowBulkSelect => ({
    sectionId: 'otherTeams',
    sectionTitle: 'Other Teams',
    allowBulkSelect
  })
};
export const getSection = (groupKey, options, {
  hasMultiplePages,
  isFiltered
}) => {
  return {
    ...getOptionalConfigForGroupKey[groupKey]?.(!hasMultiplePages && !isFiltered),
    items: options.map(({
      node
    }) => {
      const {
        displayIcon,
        id,
        publicId,
        name,
        colorKey,
        workflow_ids
      } = node;
      const item = getTeamItem({
        team: {
          display_icon: displayIcon ? {
            url: displayIcon.thumbnailUrl
          } : null,
          id,
          publicId,
          name,
          workflow_ids,
          color_key: colorKeyMap[colorKey] || colorKey
        }
      });
      item.additionalData.team = node;
      return item;
    })
  };
};