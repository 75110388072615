import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { useContext } from 'react';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { StoryCardInternal } from '../../../../internal';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LabelChips = () => {
  const story = useContext(StoryContext);
  if (!story) return null;
  return _jsx(StoryCardInternal.Chips.LabelChips, {
    labels: story.labels.map(({
      color,
      id,
      name
    }) => ({
      color: color,
      id: String(id),
      name: name
    }))
  });
};
LabelChips.displayName = "LabelChips";