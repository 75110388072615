import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { useConfirmationDialogState } from '@clubhouse/shared/components/ConfirmationDialog';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { deleteTask } from 'data/entity/task';
import { useLegacyTasksContext } from '../LegacyTasksContext';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function DeleteAllTasksActions() {
  const {
    tasks,
    isLoading,
    setIsLoading
  } = useLegacyTasksContext();
  const {
    openDialog,
    closeDialog
  } = useConfirmationDialogState();
  return _jsx(MoreActions.Item, {
    icon: "Trash",
    isDisabled: isLoading,
    onClick: () => {
      openDialog({
        body: 'You are about to delete all Tasks. This action cannot be undone.',
        buttonKind: 'warning',
        cta: 'Delete all Tasks',
        onClick: async () => {
          closeDialog();
          setIsLoading(true);
          const taskDeletions = tasks.map(task => () => deleteTask(task));

          // We can't parallelize the deletion of tasks, or we will hit conflicts.
          // Also, we can't just keep the reference to the task, because the actual
          // task could be impacted by the deletion of a previous task.
          // Therefore we create a queue of deletion functions, and we execute them one by one.
          for (const taskDeletion of taskDeletions) {
            await taskDeletion();
          }
        },
        title: 'Confirm Deletion'
      });
    },
    children: "Delete all Tasks"
  });
}
DeleteAllTasksActions.displayName = "DeleteAllTasksActions";