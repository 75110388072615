import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import { useInlineEditing } from '@clubhouse/shared/components/Table';
import { FIELD_ID } from 'components/shared/table/types';
import { getById as getGroupById } from 'data/entity/group';
import { saveChanges } from 'data/entity/story';
import { getById as getWorkflowById, useWorkflows } from 'data/entity/workflow';
import { workflow } from 'utils/sort';
import { TableWorkflow } from './tableComponents';
import { getWorkflowUpdates } from './utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function WorkflowFieldComponent({
  entity
}) {
  const {
    workflows
  } = useWorkflows();
  const [workflowId, saveChange] = useInlineEditing(entity.workflow_id, workflowId => {
    const updates = getWorkflowUpdates({
      entity,
      workflowId: Number(workflowId)
    });
    return saveChanges(entity.id, updates);
  });
  const workflow = workflowId ? getWorkflowById(workflowId) : undefined;
  const group = entity?.group_id ? getGroupById(entity.group_id) : undefined;
  const permittedWorkflowIds = group?.workflow_ids;
  const permittedWorkflows = permittedWorkflowIds ? permittedWorkflowIds.map(id => getWorkflowById(id)).filter(workflow => !!workflow) : workflows;
  return _jsx(TableWorkflow, {
    onChange: saveChange,
    showFooterMessage: Boolean(entity.group_id),
    workflow: workflow,
    workflows: permittedWorkflows
  });
}
WorkflowFieldComponent.displayName = "WorkflowFieldComponent";
export const WorkflowField = () => ({
  name: FIELD_ID.WORKFLOW,
  displayName: 'Workflow',
  sort: workflow,
  Component: WorkflowFieldComponent,
  width: 170
});