import { Icon } from '@clubhouse/shapes-ds';
import { AlertMessage, AlertWrapper } from '@clubhouse/shared/components/AlertMessage';
import { Button } from '@clubhouse/shared/components/Button';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ArchiveMessage = ({
  children,
  className,
  typeName,
  onUnarchive,
  onDelete
}) => {
  return _jsxs(AlertWrapper, {
    className: className,
    kind: AlertMessage.KIND.ARCHIVED,
    children: [_jsx(AlertWrapper.Icon, {
      children: _jsx(Icon, {
        icon: "Archive"
      })
    }), _jsx(AlertWrapper.Text, {
      size: "large",
      children: _jsxs(Spaced, {
        amount: "space1",
        children: [_jsxs(Text, {
          bold: true,
          size: Text.SIZE.LARGE,
          children: ["This ", typeName.toLowerCase(), " is archived."]
        }), children && _jsx(Text, {
          size: Text.SIZE.SMALL,
          children: children
        })]
      })
    }), _jsxs(AlertWrapper.CTA, {
      children: [_jsx(Button, {
        "data-perma-id": "archive-message-unarchive",
        onClick: onUnarchive,
        kind: Button.KIND.SECONDARY,
        children: "Unarchive"
      }), onDelete && _jsx(Button, {
        "data-perma-id": "archive-message-delete",
        onClick: onDelete,
        kind: Button.KIND.WARNING,
        children: "Delete"
      })]
    })]
  });
};
ArchiveMessage.displayName = "ArchiveMessage";