import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { MutateStoryProductAreaDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { ProductAreaFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { QueryStoryProductAreaSelectDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { ProductAreaFieldItemFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { Icon } from '@clubhouse/shapes-ds';
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { NONE_VALUE, Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsFieldSingleSelection } from 'components/gql/GroupedOptionsField';
import { ProductAreaSelectTarget } from 'components/gql/productArea/ProductAreaSelectTarget';
import { createOptimisticStoryMutationResponse } from 'components/gql/utils/mutation';
import { useMutation } from 'gql';
import { useFieldOptionsQuery } from './useFieldOptionsQuery';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ProductAreaFieldItemFragment = ProductAreaFieldItemFragmentDoc;
const QUERY_STORY_PRODUCT_AREA_SELECT = QueryStoryProductAreaSelectDocument;
export const ProductAreaFieldFragment = ProductAreaFieldFragmentFragmentDoc;
const MUTATE_STORY_PRODUCT_AREA_FIELD = MutateStoryProductAreaDocument;
const getSection = (groupKey, options) => ({
  sectionId: groupKey,
  items: options.map(({
    node
  }) => ({
    value: node.id,
    name: node.stringValue,
    additionalData: node,
    Icon: () => _jsx(DeprecatedIconAdapter, {
      fill: node.colorKey,
      children: _jsx(Icon, {
        icon: "FeatureArea"
      })
    })
  }))
});
export const ProductAreaFieldComponent = ({
  entity: {
    id,
    productArea
  }
}) => {
  const fetchProductAreas = useFieldOptionsQuery({
    query: QUERY_STORY_PRODUCT_AREA_SELECT,
    storyId: id,
    includeCurrentPermissionId: false
  });
  const [updateStoryProductArea] = useMutation(MUTATE_STORY_PRODUCT_AREA_FIELD);
  const handleChange = useCallback(async selectedId => {
    await updateStoryProductArea({
      variables: {
        storyId: id,
        input: {
          canonicalName: 'productArea',
          valueId: selectedId ?? NONE_VALUE
        }
      },
      optimisticResponse: createOptimisticStoryMutationResponse(id, {
        productArea: selectedId ? {
          __typename: 'CustomFieldEnumValue',
          id: selectedId
        } : null
      })
    });
  }, [updateStoryProductArea, id]);
  return _jsx(GroupedOptionsFieldSingleSelection, {
    selectedEntity: productArea,
    fetchOptions: fetchProductAreas,
    getSection: getSection,
    TargetComponent: ProductAreaSelectTarget,
    onChange: handleChange,
    unit: Nouns.ProductArea
  });
};
ProductAreaFieldComponent.displayName = "ProductAreaFieldComponent";
ProductAreaFieldComponent.displayName = 'ProductAreaFieldComponent';
export const ProductAreaField = () => ({
  name: 'productArea',
  displayName: Nouns.ProductArea.singular,
  Component: ProductAreaFieldComponent,
  width: 110,
  sort: true
});