import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.reduce.js";
import StoryModel from 'app/client/core/js/models/story';
import StoryDialogController from 'app/client/core/js/controllers/storyDialog';
import StoryController from 'app/client/core/js/controllers/story';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Model', 'Story'], StoryModel], [['Controller', 'StoryDialog'], StoryDialogController], [['Controller', 'Story'], StoryController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { getHref } from 'components/gql/stories/links';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ReactionCreate = ({
  name,
  story,
  emoji,
  isAuthor
}) => _jsxs("div", {
  className: "value",
  children: [_jsx("strong", {
    children: _jsx(Emojify, {
      children: name
    })
  }), ' ', "reacted with ", _jsx(Emojify, {
    children: emoji
  }), " to ", isAuthor ? 'your' : 'a', " comment on", ' ', _jsx("a", {
    href: getHref(story.id, story.name),
    className: "entity-title-link",
    "data-model": "Story",
    "data-tooltip": true,
    "data-tooltip-fn": "App.Controller.Story.renderStoryTooltip",
    "data-on-click": "App.Controller.StoryDialog.open",
    "data-id": story.id,
    children: _jsx("strong", {
      children: _jsx(Emojify, {
        children: story.name
      })
    })
  })]
});
ReactionCreate.displayName = "ReactionCreate";