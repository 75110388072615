import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { avgCol, avgRow } from './avg';
import { sumCol, sumRow } from './sum';
export const FNS = {
  AVGCOL: {
    label: 'Average Column',
    fn: avgCol
  },
  AVGROW: {
    label: 'Average Row',
    fn: avgRow
  },
  SUMCOL: {
    label: 'Sum Column',
    fn: sumCol
  },
  SUMROW: {
    label: 'Sum Row',
    fn: sumRow
  }
};
const IDENTIFIERS = Object.keys(FNS);
export const handleTableFns = html => {
  const node = document.createElement('div');
  node.innerHTML = html;
  node.querySelectorAll('td').forEach(td => {
    const text = td.textContent;
    if (!text || !new RegExp(`=(?:${IDENTIFIERS.join('|')})`, 'g').test(text)) return;
    const ids = IDENTIFIERS.filter(id => {
      const re = new RegExp(`=${id}`);
      return re.test(text);
    });
    for (const id of ids) {
      td.innerHTML = td.innerHTML.replace(new RegExp(`=${id}`), String(FNS[id].fn(td)));
    }
  });
  return node.innerHTML;
};