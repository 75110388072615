import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { GetStorySubtaskIndicatorStateStoryFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
export const GetStorySubtaskIndicatorStateStoryFragment = GetStorySubtaskIndicatorStateStoryFragmentDoc;
export function getStorySubtaskIndicatorState(story) {
  if (!story) return null;
  if (story.parentStory) {
    // It's a Sub-task.
    return {
      kind: 'subtask'
    };
  }
  const subtasks = story.subTasks?.edges.filter(({
    node
  }) => !node.archived);
  if (subtasks?.length) {
    // It's the parent of at least 1 Non-archived Sub-task.
    return {
      kind: 'subtask-parent',
      subtasks
    };
  }
  return null;
}