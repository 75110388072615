import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
export const toUppercase = fieldName => form => {
  const unsubscribe = form.subscribe(({
    values
  }) => {
    if (values[fieldName]) {
      form.change(fieldName, values[fieldName].toUpperCase().trim());
    }
  }, {
    values: true
  });
  return unsubscribe;
};
export const trim = fieldNames => form => {
  const unsubscribe = form.subscribe(({
    values
  }) => {
    fieldNames.forEach(f => {
      if (values[f]) {
        form.change(f, values[f].trim());
      }
    });
  }, {
    values: true
  });
  return unsubscribe;
};