import { MutateTeamFieldDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { QueryEpicTeamSelectDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { SkeletonCircle } from '@clubhouse/shared/components/SkeletonCircle';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsFieldSingleSelection } from 'components/gql/GroupedOptionsField';
import { TeamSelectTarget } from 'components/gql/team/TeamSelectTarget';
import { getSection } from 'components/gql/team/utils/sections';
import { createOptimisticEpicMutationResponse } from 'components/gql/utils/mutation';
import { useMutation } from 'gql';
import { useFieldOptionsQuery } from './useFieldOptionsQuery';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const QUERY_EPIC_TEAM_SELECT = QueryEpicTeamSelectDocument;
export const MUTATE_TEAM_FIELD = MutateTeamFieldDocument;
export const TeamFieldComponent = ({
  entity: {
    id,
    teams
  }
}) => {
  const fetchTeams = useFieldOptionsQuery({
    query: QUERY_EPIC_TEAM_SELECT,
    epicId: id,
    includeCurrentPermissionId: true
  });
  const [executeMutation] = useMutation(MUTATE_TEAM_FIELD);
  const handleChange = useCallback(async selectedId => {
    executeMutation({
      variables: {
        epicId: id,
        input: {
          team: selectedId ?? null
        }
      },
      optimisticResponse: createOptimisticEpicMutationResponse(id, {
        team: selectedId ? {
          __typename: 'Team',
          id: selectedId
        } : null
      })
    });
  }, [id, executeMutation]);
  return _jsx(Center, {
    children: _jsx("div", {
      children: _jsx(GroupedOptionsFieldSingleSelection, {
        selectedEntity: teams?.edges?.length ? teams.edges[0]?.node : null,
        fetchOptions: fetchTeams,
        getSection: getSection,
        TargetComponent: TeamSelectTarget,
        onChange: handleChange,
        unit: Nouns.Team
      })
    })
  });
};
TeamFieldComponent.displayName = "TeamFieldComponent";
TeamFieldComponent.displayName = 'TeamFieldComponent';
export const TeamField = () => ({
  name: 'team',
  displayName: 'Team',
  headerProps: {
    centered: true
  },
  Component: TeamFieldComponent,
  LoadingComponent: ({
    animate
  }) => _jsx(Center, {
    children: _jsx(SkeletonCircle, {
      size: 24,
      animate: animate
    })
  }),
  width: 60,
  sort: 'team_name'
});