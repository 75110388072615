import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { getItemConfigForPermission } from './getItemConfigForPermission';
const getOptionalConfigForGroupKey = {
  selectedPermission: null,
  epicOwner: null,
  currentPermission: null,
  storyOwner: null,
  currentPermissionTeammate: () => ({
    sectionId: 'currentPermissionTeamMember',
    sectionTitle: 'Team Members',
    allowBulkSelect: false
  }),
  epicTeamMember: () => ({
    sectionId: 'epicTeamMember',
    sectionTitle: "Selected Team's Members",
    allowBulkSelect: false
  }),
  storyTeamMember: () => ({
    sectionId: 'storyTeamMember',
    sectionTitle: "Selected Team's Members",
    allowBulkSelect: false
  }),
  default: () => ({
    sectionId: 'otherMembers',
    sectionTitle: 'Other Members',
    allowBulkSelect: false
  })
};
export const getSection = (groupKey, ownerSelectOptions) => {
  return {
    ...getOptionalConfigForGroupKey[groupKey]?.(),
    items: ownerSelectOptions.map(({
      node
    }) => getItemConfigForPermission(node))
  };
};