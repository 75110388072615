import { DeleteStoryActionFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { Nouns } from '@clubhouse/shared/constants';
import { useSubtaskLoadingContext } from 'components/stories/StorySubtasks/SubtaskLoadingContext';
import { deleteStory } from 'data/entity/story';
import { useCallback } from 'react';
import { closeCurrentStoryDialog } from 'utils/storyDialog';
import { useStoryConfirmationDialog } from '../hooks/useStoryConfirmationDialog';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const DeleteStoryActionFragment = DeleteStoryActionFragmentDoc;
export const useDeleteStoryDialog = (story, {
  isSubtask = false,
  keepStoryDialogOpen = false
} = {}) => {
  const {
    openDialog,
    closeDialog
  } = useStoryConfirmationDialog();
  const {
    id: storyId,
    name,
    subtaskId
  } = story;
  const {
    handleSubtaskLoading
  } = useSubtaskLoadingContext();
  const onDelete = useCallback(() => {
    openDialog(isSubtask ? 'deleteSubtask' : 'delete', {
      onClick: async () => {
        try {
          closeDialog();
          if (isSubtask) handleSubtaskLoading(true, subtaskId);
          await deleteStory(storyId);
          // We only want to close the Story Dialog when deleting a "regular" story and not when deleting a Sub-task from within its parent Story.
          if (!keepStoryDialogOpen) closeCurrentStoryDialog();
          addToast({
            kind: 'success',
            timeout: 5000,
            Content: () => _jsxs(ToastText, {
              children: [isSubtask ? Nouns.Subtask.singular : Nouns.Story.singular, ' ', _jsx("strong", {
                children: _jsx(Emojify, {
                  children: name
                })
              }), ' ', "deleted."]
            })
          });
        } catch {
          if (isSubtask) handleSubtaskLoading(false, subtaskId);
          addToast({
            kind: 'alert',
            Content: () => _jsxs(ToastText, {
              children: ["We were unable to delete", ' ', _jsx("strong", {
                children: _jsx(Emojify, {
                  children: name
                })
              })]
            })
          });
        }
      }
    });
  }, [name, isSubtask, storyId, closeDialog, openDialog, keepStoryDialogOpen, handleSubtaskLoading, subtaskId]);
  return onDelete;
};
export const DeleteStoryAction = ({
  id: storyId,
  name,
  isSubtask = false,
  keepStoryDialogOpen = false,
  subtaskId
}) => {
  const onDelete = useDeleteStoryDialog({
    id: storyId,
    name,
    subtaskId
  }, {
    isSubtask,
    keepStoryDialogOpen
  });
  return _jsxs(MoreActions.Item, {
    icon: "Trash",
    onClick: onDelete,
    children: ["Delete ", isSubtask ? Nouns.Subtask.singular : Nouns.Story.singular]
  });
};
DeleteStoryAction.displayName = "DeleteStoryAction";