import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import identity from 'lodash/identity';
import * as FeatureToggles from '@clubhouse/feature-toggles';
import * as LocalStorage from 'utils/localStorage';
import { getWindow } from './windowAdapter';

/*
Some of this code is copied from https://github.com/facebook/create-react-app/blob/master/packages/cra-template/template/src/serviceWorker.js
For more details about the create-react-app implementation, read https://bit.ly/CRA-PWA

Manual Tests
============

- When browser does not support a service worker and when visiting /<slug>/stories
  - then there should not be an JS errors

- When service worker is not registered
  - and when USE_SERVICE_WORKER feature toggle is enabled, and when page is refreshed
    - then the service worker should be registered
    - then the service worker should handle whitelisted responses
    - and if requested url is cached and if database time is before current database time
      - then the page should update with the most recent data immediately after page refresh
    - and when navigating to another page
      - then the service worker should not be registered again

  - and when USE_SERVICE_WORKER feature toggle is disabled, and when page is refreshed
    - then the service worker should not be unregistered

- When service worker is registered
  - and when USE_SERVICE_WORKER is enabled, and when visiting `/story/<id>`, and when refreshing
    - and when updating the story owner, and when refreshing again
      - then it should show the most recent info
  - and when USE_SERVICE_WORKER is enabled, and when page is refreshed
    - then the service worker should be registered
    - then the service worker should handle whitelisted responses
    - and when the service worker is updated
      - and when the page is refreshed
        - then the updated service worker should immediately move from the `waiting` state to the activated state
        - then the service worker should handle whitelisted responses
        - and if the cache key changes
          - then the stale api-cache should be removed
        - and if the cache key does not change
          - then the api-cache should not be removed
    - and when navigating to another page
      - then any endpoints previously requested should not be fetched from the cache again
    - and when switching workspaces
      - then the system should not show data from the previous workspace

  - and when USE_SERVICE_WORKER is disabled, and when page is refreshed
    - then the service worker should be unregistered
    - then requests should not be handled by service worker
    - then the api-cache should be removed

  - and when two tabs are open with the same domain
    - and when USE_SERVICE_WORKER is disabled, and when page is refreshed
      - then the service worker should be unregistered
      - then requests should not be handled by service worker
      - then the api-cache should be removed
        - NOTE: the cache is only removed after both tabs have refreshed

  - When localStorage.enable_service_worker is set to "true"
    - then the service worker should register

  - When localStorage.enable_service_worker is set to "false"
    - then the service worker should unregister
    - then the api-cache should be removed
*/

const getLocalStorageFlag = () => {
  const value = LocalStorage.get('enable_service_worker');
  if (value === undefined) {
    return null;
  }
  return JSON.parse(value);
};
const isServiceWorkerSupported = () => 'serviceWorker' in navigator && typeof caches !== 'undefined';
export const isServiceWorkerEnabled = () => isServiceWorkerSupported() && (getLocalStorageFlag() !== null ? getLocalStorageFlag() : FeatureToggles.getFeatureToggle(FeatureToggles.FEATURE_TOGGLES.USE_SERVICE_WORKER));
const deleteCache = async ({
  filter = identity
} = {}) => {
  return Promise.all((await caches.keys()).filter(filter).map(key => caches.delete(key)));
};
const deleteStaleCache = async () => {
  const filter = key => key !== getWindow()._SERVICE_WORKER_CACHE_NAME;
  return deleteCache({
    filter
  });
};
export const hasCachedEntitiesOnInitialLoad = async () => {
  if (!isServiceWorkerSupported()) {
    return false;
  }

  // This value is null if this page was loaded with a hard refresh
  // https://www.w3.org/TR/service-workers/#navigator-service-worker-controller
  if (navigator.serviceWorker.controller === null) {
    return false;
  }
  try {
    const cache = await caches.open('clubhouse-api-v2');
    const keys = await cache.keys();
    return keys.length > 0;
  } catch (e) {
    return false;
  }
};
export const deleteRequestCacheByUrl = async url => {
  if (!isServiceWorkerSupported()) {
    return;
  }
  try {
    const cache = await caches.open('clubhouse-api-v2');
    const keys = await cache.keys();
    const request = keys.find(cachedRequest => {
      return cachedRequest.url.includes(url);
    });
    if (!request) {
      return;
    }
    cache.delete(request);
  } catch (e) {}
};
const registerValidSW = swUrl => {
  // @ts-ignore the __WB_DISABLE_DEV_LOGS is observed by workbox
  navigator.serviceWorker.__WB_DISABLE_DEV_LOGS = true;
  navigator.serviceWorker.register(swUrl, {
    scope: '/'
  }).then(registration => {
    if (!registration) return;
    registration.onupdatefound = () => {
      const installingWorker = registration.installing;
      if (!installingWorker) {
        return;
      }
      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'installed') {
          deleteStaleCache();
          if (navigator.serviceWorker.controller) {
            console.log('Service worker updated.');
          } else {
            console.log('Service Worker installed.');
          }
        }
      };
    };
  }).catch(error => {
    console.error('Error during service worker registration:', error);
  });
};
const unregister = () => {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();
  }).catch(error => {
    console.error(error.message);
  });
};
export const unregisterAndDeleteCache = () => {
  if (!isServiceWorkerSupported()) {
    return;
  }
  unregister();
  deleteCache();
};
export const initServiceWorker = async ({
  user = null
} = {}) => {
  if (!isServiceWorkerSupported()) {
    return;
  }

  // We need to unregister and delete the cache if the user is not logged in so that any Cache Storage items are deleted
  // https://app.shortcut.com/internal/story/156992/investigate-potential-service-worker-data-leak
  if (!isServiceWorkerEnabled() || !user) {
    unregisterAndDeleteCache();
    return;
  }
  return registerValidSW(`${getWindow()._STATIC_ASSETS_URL}/lib/service-worker.js`);
};