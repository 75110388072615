import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { useEffect } from 'react';
import { isReadOnly } from 'data/entity/user';
export const useCheckboxes = (ref, md, onChange) => {
  useEffect(() => {
    if (!ref || !onChange || isReadOnly()) return;
    const getTaskItems = () => [...ref.querySelectorAll('input[type="checkbox"][data-md="true"]')];
    const disableAllCheckboxes = () => {
      getTaskItems().forEach(el => {
        el.setAttribute('disabled', 'disabled');
      });
    };
    const enableAllCheckboxes = () => {
      getTaskItems().forEach(el => {
        el.removeAttribute('disabled');
      });
    };
    enableAllCheckboxes();
    const handleChange = async e => {
      if (e.target instanceof HTMLInputElement && e.target.type === 'checkbox') {
        const index = getTaskItems().indexOf(e.target);
        if (index >= 0) {
          const checked = e.target.checked;
          let found = null;
          const regexp = /(-|\+|\*) \[(x|\s)\]/gi;
          let i = 0;
          do {
            found = regexp.exec(md);
            if (found && i === index && found[2]?.toLowerCase() === (checked ? ' ' : 'x')) {
              disableAllCheckboxes();
              const before = md.substring(0, found.index);
              const after = md.substring(found.index + found[0].length);
              const newMd = `${before}${found[1]} [${checked ? 'x' : ' '}]${after}`;
              await onChange(newMd);
              enableAllCheckboxes();
              break;
            }
            ++i;
          } while (found);
        }
      }
    };
    ref.addEventListener('change', handleChange);
    return () => {
      disableAllCheckboxes();
      ref.removeEventListener('change', handleChange);
    };
  }, [md, onChange, ref]);
};