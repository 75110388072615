import { OwnerPermissionFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import { MutateStoryOwnersFieldDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { QueryStoryOwnerSelectDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { StoryOwnersFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useApolloClient } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { SkeletonCircle } from '@clubhouse/shared/components/SkeletonCircle';
import { Nouns } from '@clubhouse/shared/constants/nouns';
import { GroupedOptionsField } from 'components/gql/GroupedOptionsField';
import { OwnersFieldTarget } from 'components/gql/owner/OwnersFieldTarget';
import { getSection } from 'components/gql/owner/utils/sections';
import { createOptimisticStoryMutationResponse } from 'components/gql/utils/mutation';
import { useMutation } from 'gql';
import { useFieldOptionsQuery } from './useFieldOptionsQuery';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const StoryOwnersFieldFragment = StoryOwnersFieldFragmentFragmentDoc;
const QUERY_STORY_OWNER_SELECT = QueryStoryOwnerSelectDocument;
const MUTATE_STORY_OWNERS_FIELD = MutateStoryOwnersFieldDocument;
export const OwnersFieldComponent = ({
  entity: {
    id,
    owners
  }
}) => {
  const client = useApolloClient();
  const fetchOwners = useFieldOptionsQuery({
    query: QUERY_STORY_OWNER_SELECT,
    storyId: id,
    includeCurrentPermissionId: true
  });
  const [save] = useMutation(MUTATE_STORY_OWNERS_FIELD);
  const handleChange = useCallback(async selectedIds => {
    const sortedPermissions = selectedIds.map(id => {
      return client.readFragment({
        id: client.cache.identify({
          id,
          __typename: 'Permission'
        }),
        fragment: OwnerPermissionFragmentDoc
      });
    }).filter(permission => !!permission).sort((a, b) => a.displayName.localeCompare(b.displayName));
    return save({
      variables: {
        storyId: id,
        input: {
          owners: {
            reset: selectedIds
          }
        }
      },
      optimisticResponse: createOptimisticStoryMutationResponse(id, {
        owners: {
          __typename: 'PermissionConnection',
          edges: sortedPermissions.map(({
            id
          }) => ({
            __typename: 'PermissionEdge',
            node: {
              __typename: 'Permission',
              id
            }
          }))
        }
      })
    });
  }, [client.readFragment, client.cache.identify, save, id]);
  const selectedOwners = useMemo(() => (owners?.edges || []).map(({
    node: {
      id,
      state,
      email,
      displayName,
      mentionName,
      displayIcon
    }
  }) => ({
    id,
    state,
    email_address: email,
    name: displayName,
    mention_name: mentionName,
    ...(displayIcon?.thumbnailUrl ? {
      display_icon: {
        url: displayIcon.thumbnailUrl
      }
    } : {
      display_icon: null
    })
  })), [owners?.edges]);
  return _jsx(Center, {
    children: _jsx("div", {
      children: _jsx(GroupedOptionsField, {
        selectedEntities: selectedOwners,
        fetchOptions: fetchOwners,
        getSection: getSection,
        onChange: handleChange,
        TargetComponent: OwnersFieldTarget,
        unit: Nouns.Owner
      })
    })
  });
};
OwnersFieldComponent.displayName = "OwnersFieldComponent";
OwnersFieldComponent.displayName = 'OwnersFieldComponent';
export const OwnersField = () => ({
  name: 'owners',
  displayName: 'Owners',
  sort: true,
  headerProps: {
    centered: true
  },
  Component: OwnersFieldComponent,
  LoadingComponent: ({
    animate
  }) => _jsx(Center, {
    children: _jsx(SkeletonCircle, {
      size: 24,
      animate: animate
    })
  }),
  width: 110
});