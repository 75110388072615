import { StorySubtaskActionMenuStoryFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Button } from '@clubhouse/shared/components/Button';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { DeleteStoryAction } from 'components/gql/stories/actions/DeleteStoryAction';
import { DetachFromParentAction } from './DetachFromParentAction';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const StorySubtaskActionMenuStoryFragment = StorySubtaskActionMenuStoryFragmentDoc;
export const StorySubtaskActionMenu = ({
  subtask
}) => {
  return _jsxs(MoreActions, {
    children: [_jsx(MoreActions.Trigger, {
      asChild: true,
      children: _jsx(Button, {
        kind: "ghost",
        fit: "exact",
        size: "small",
        children: _jsx(SizedIcon, {
          size: 18,
          icon: "More",
          label: "Open actions menu"
        })
      })
    }), _jsxs(MoreActions.Menu, {
      children: [_jsx(DetachFromParentAction, {
        subtask: subtask
      }), _jsx(MoreActions.Divider, {}), _jsx(DeleteStoryAction, {
        id: subtask.publicId,
        name: subtask.name,
        subtaskId: subtask.id,
        isSubtask: true,
        keepStoryDialogOpen: true
      })]
    })]
  });
};
StorySubtaskActionMenu.displayName = "StorySubtaskActionMenu";