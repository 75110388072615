import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { Swatch } from 'components/shared/Swatch';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const getSection = (_, options) => ({
  items: options.map(({
    node: {
      id,
      name,
      color
    }
  }) => ({
    value: id,
    name: _jsx(Emojify, {
      children: name
    }),
    Icon: () => _jsx(Swatch, {
      backgroundColor: color
    })
  }))
});