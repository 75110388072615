import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { QueryTeamNodeForTooltipDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { TeamTooltip as TeamTooltipShared } from '@clubhouse/shared/components/TeamTooltip';
import { AsyncTooltip } from '@clubhouse/shared/components/Tooltip';
import { getTeamColor } from '@clubhouse/shared/utils/teams';
import { useLazyQuery } from 'gql';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const QUERY_TEAM_NODE_FOR_TOOLTIP = QueryTeamNodeForTooltipDocument;
export const TeamTooltip = ({
  teamId,
  children
}) => {
  const [fetchTeam] = useLazyQuery(QUERY_TEAM_NODE_FOR_TOOLTIP);
  const fetchTooltipData = useCallback(async () => {
    if (!teamId) return null;
    const {
      data
    } = await fetchTeam({
      variables: {
        id: teamId
      }
    });
    const {
      node: team
    } = data || {};
    if (team?.__typename !== 'Team') return null;
    return _jsx(TeamTooltipShared.DefaultTeamTooltipContent, {
      name: team.name || undefined,
      mentionName: team.mentionName || undefined,
      totalMemberCount: team.members?.pageInfo.totalSize,
      members: team.members?.edges.map(({
        node: {
          id,
          email,
          mentionName,
          displayName,
          displayIcon
        }
      }) => ({
        id,
        email,
        mentionName,
        fullName: displayName,
        imageUrl: displayIcon?.thumbnailUrl
      })),
      teamColor: getTeamColor(team),
      imageUrl: team.displayIcon?.thumbnailUrl || undefined
    });
  }, [fetchTeam, teamId]);
  return _jsx(AsyncTooltip, {
    fetchContent: fetchTooltipData,
    children: children
  });
};
TeamTooltip.displayName = "TeamTooltip";