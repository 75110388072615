import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import { useState } from 'react';
import { AlertMessage } from '@clubhouse/shared/components/AlertMessage';
import { Button } from '@clubhouse/shared/components/Button';
import { capitalize } from '@clubhouse/shared/utils';
import { SelectStoryType, StoryTypes } from '../shared/SelectStoryType';
import { Actions } from './shared/Actions';
import { MappingHeader, MappingList, MappingRow } from './shared/MappingList';
import { Para } from './shared/Para';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const from = jiraType => _jsx("span", {
  children: capitalize(jiraType.name)
});
from.displayName = "from";
const to = (i, jiraType, onSelect) => {
  return _jsx(SelectStoryType, {
    initialValue: jiraType.target_type,
    onSelect: s => onSelect(i, s)
  });
};
to.displayName = "to";
const initialData = [{
  id: '1',
  name: 'bug'
}, {
  id: '2',
  name: 'subtask'
}, {
  id: '3',
  name: 'task'
}, {
  id: '4',
  name: 'story'
}];
const excludedJiraStoryTypes = ['epic'];
const setInitialStoryTypes = storyTypes => storyTypes.filter(s => !excludedJiraStoryTypes.includes(s.name.toLowerCase())).map(s => !s.target_type ? {
  ...s,
  target_type: s.name.toLowerCase() === StoryTypes.BUG ? StoryTypes.BUG : StoryTypes.FEATURE
} : s);
export const MapToStoryType = ({
  goBack,
  onDone,
  importMap
}) => {
  const [storyTypes, setStoryTypes] = useState(setInitialStoryTypes(importMap.story_types || initialData));
  const onSelect = (index, selectedValue) => {
    const clone = storyTypes.slice(0);
    clone[index].target_type = selectedValue;
    setStoryTypes(clone);
  };
  return _jsxs(_Fragment, {
    children: [_jsxs(Para, {
      children: ["Map your Jira Issue Types to one of three Story types in ", BRAND.NAME, ": Feature, Bug, or Chore. We will also apply a Label with the previous Jira Issue Type."]
    }), _jsx(AlertMessage, {
      kind: AlertMessage.KIND.INFO,
      children: _jsxs("div", {
        "data-perma-id": "map-story-types",
        children: ["Jira ", _jsx("strong", {
          children: "Epics"
        }), " will be imported as ", BRAND.NAME, " Epics. Jira ", _jsx("strong", {
          children: "Subtasks"
        }), " will be imported as ", BRAND.NAME, " Stories with a dependency on the parent Story from Jira."]
      })
    }), _jsxs(MappingList, {
      children: [_jsx(MappingHeader, {
        fromLabel: "Jira Issue Type",
        toLabel: `${BRAND.NAME} Story Type`
      }), storyTypes.map((jiraType, i) => _jsx(MappingRow, {
        from: from(jiraType),
        to: to(i, jiraType, onSelect)
      }, jiraType.id))]
    }), _jsxs(Actions, {
      children: [_jsx(Button, {
        kind: Button.KIND.SECONDARY,
        onClick: goBack,
        children: "Go Back"
      }), _jsx(Button, {
        kind: Button.KIND.PRIMARY,
        showProgress: false,
        onClick: () => onDone(storyTypes),
        children: "Next: Map Workflow States"
      })]
    })]
  });
};