import { Icon } from '@clubhouse/shapes-ds';
import { FIELD_ID } from 'components/shared/table/types';
import { position } from 'utils/sort';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HeaderComponent = () => _jsx(Icon, {
  icon: "Handle",
  fill: "currentColor"
});
HeaderComponent.displayName = "HeaderComponent";
export const PriorityField = (overrides = {}) => ({
  name: FIELD_ID.PRIORITY,
  displayName: 'Priority',
  sort: position,
  HeaderComponent,
  width: 50,
  maxWidth: 50,
  headerProps: {
    centered: true
  },
  frozen: {
    left: 0
  },
  ...overrides
});