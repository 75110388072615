import { StorySubtaskIcon } from './components/StorySubtaskIcon';
import { StorySubtaskParentChip } from './components/StorySubtaskParentChip';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function StorySubtaskIndicator({
  storySubtaskIndicatorState,
  setParentChipWidth
}) {
  if (!storySubtaskIndicatorState) return null;
  return storySubtaskIndicatorState.kind === 'subtask-parent' ? _jsx(StorySubtaskParentChip, {
    subtasks: storySubtaskIndicatorState.subtasks,
    setParentChipWidth: setParentChipWidth
  }) : _jsx(StorySubtaskIcon, {});
}