import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import StoryController from 'app/client/core/js/controllers/story';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Story'], StoryController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { useMemo } from 'react';
import { useOpenSelect } from '@clubhouse/shared/components/Select/useOpenSelect';
import { useInlineEditing } from '@clubhouse/shared/components/Table';
import { IterationSelect } from 'components/shared/IterationSelect';
import { IterationStatusIcon } from 'components/shared/IterationStatusIcon';
import { useCommandBarCallback } from 'components/shared/command-bar/hooks/useCommandBarCallback';
import { useIterations } from 'data/entity/iteration';
import { setIteration } from 'data/entity/story';
import { isReadOnly } from 'data/entity/user';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Target = ({
  story,
  iteration
}) => {
  const openMenu = useOpenSelect();
  useCommandBarCallback('openIterationSelector', openMenu, {
    skip: isReadOnly()
  });
  return _jsxs("div", {
    "data-testid": `iteration-select-${story?.id}`,
    className: "attribute editable-attribute attribute-has-toggle story-iteration has-icon-on-left",
    "data-tabindex": true,
    children: [_jsx("span", {
      className: "custom-icon",
      children: _jsx(IterationStatusIcon, {
        iteration: iteration,
        width: "20px"
      })
    }), _jsx("span", {
      "data-testid": "attribute-name",
      className: "name",
      children: "Iteration"
    }), _jsx("span", {
      "data-testid": "attribute-value",
      className: "value",
      children: iteration ? iteration.name : _jsx("em", {
        children: "None"
      })
    }), !!story.previous_iteration_ids?.length && _jsxs("div", {
      className: "previous-iterations-badge",
      "data-tooltip": true,
      "data-tooltip-fn": "App.Controller.Story.previousIterationsTooltip",
      children: ["+", story.previous_iteration_ids.length]
    })]
  });
};
Target.displayName = "Target";
export const StoryIteration = ({
  story,
  iteration
}) => {
  const {
    iterations
  } = useIterations();
  const [selectedValue, onChange] = useInlineEditing(iteration?.id, iterationId => setIteration(story, iterationId));
  const readOnly = isReadOnly();

  // biome-ignore lint/correctness/useExhaustiveDependencies: We use the iteration ids as cache key to avoid re-rendering when objects (but not the actual values) change.
  const cachedIterations = useMemo(() => iterations, [String(iterations.map(i => i.id))]);
  return _jsx(IterationSelect, {
    isReadOnly: readOnly,
    groupId: story.group_id,
    iterationId: selectedValue,
    iterations: cachedIterations,
    onChange: onChange,
    maxHeight: "45vh",
    children: _jsx(Target, {
      story: story,
      iteration: iteration
    })
  });
};
StoryIteration.displayName = "StoryIteration";