import { MutateStartDateFieldDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { EpicStartDateFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { TableDatePicker } from 'components/shared/table/base';
import { useMutation } from 'gql';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const EpicStartDateFieldFragment = EpicStartDateFieldFragmentFragmentDoc;
const MUTATE_START_DATE_FIELD = MutateStartDateFieldDocument;
function StartDateFieldComponent({
  entity: {
    id,
    deadline,
    plannedStartDate
  }
}) {
  const [save] = useMutation(MUTATE_START_DATE_FIELD);
  const handleChange = useCallback(date => {
    save({
      variables: {
        id,
        input: {
          plannedStartDate: date
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        epicUpdate: {
          __typename: 'EpicPayload',
          epic: {
            __typename: 'Epic',
            id,
            plannedStartDate: date,
            deadline
          }
        }
      }
    });
  }, [deadline, id, save]);
  return _jsx(TableDatePicker, {
    maxDate: deadline,
    onChange: handleChange,
    value: plannedStartDate
  });
}
StartDateFieldComponent.displayName = "StartDateFieldComponent";
export const StartDateField = () => ({
  name: 'plannedStartDate',
  displayName: 'Start Date',
  headerProps: {
    centered: true
  },
  sort: true,
  Component: StartDateFieldComponent,
  LoadingComponent: ({
    animate
  }) => _jsx(Center, {
    children: _jsx(SkeletonRectangle, {
      width: 40,
      animate: animate
    })
  }),
  width: 130
});