import { UpdateSubtaskParentageMutationDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useMutation } from 'gql';
import { useWorkspaceSlug } from 'utils/navigation';
const UpdateSubtaskParentageMutation = UpdateSubtaskParentageMutationDocument;
export function useUpdateSubtaskParentageMutation() {
  const workspace2Slug = useWorkspaceSlug();
  const [updateParent] = useMutation(UpdateSubtaskParentageMutation);
  return useCallback(({
    subTaskId,
    parentStoryId
  }) => updateParent({
    variables: {
      workspace2Slug,
      input: {
        subTask: subTaskId,
        parentStory: parentStoryId
      }
    }
  }), [updateParent, workspace2Slug]);
}