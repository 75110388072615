import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
const isValidEmail = value => /^\S+@\S+\.\S+$/.test(value);
const includesMultipleEmails = value => value.split(/[,\s]+/).filter(isValidEmail).length > 1;
export const isTrimmed = (value = '') => value.trim().length === value.length;
export const isSingleEmailOrEmpty = (value = '') => {
  value = value.trim();
  if (includesMultipleEmails(value)) return 'Only add a single email per row';
  if (value === '') return true;
  if (!isValidEmail(value)) return 'This must be a valid email address';
  return true;
};