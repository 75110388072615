import { getTokens } from '@clubhouse/shapes-ds';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { useThemeName } from '@clubhouse/shared/utils/theme';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const {
  purple80: strategicIcon,
  blue80: tacticalIcon,
  blue60: darkModeTacticalIcon,
  purple60: darkModeStrategicIcon
} = getTokens().color.raw;
export function ObjectiveTypeIcon({
  type
}) {
  const themeName = useThemeName();
  return type === 'tactical' ? _jsx(SizedIcon, {
    icon: "Objectives",
    customFill: themeName === 'light' ? tacticalIcon : darkModeTacticalIcon
  }) : type === 'strategic' ? _jsx(SizedIcon, {
    icon: "Objectives",
    customFill: themeName === 'light' ? strategicIcon : darkModeStrategicIcon
  }) : _jsx(SizedIcon, {
    icon: "Objectives",
    fill: "disabled"
  });
}