import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { LoadMore } from 'components/searchResults/LoadMore';
import { NoResults } from 'components/searchResults/NoResults';
import { SkeletonSearchCard } from '../SearchCard/SkeletonSearchCard';
import { IterationSearchCardAdapter } from './IterationSearchCard';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const IterationResults = ({
  fetchProps: {
    loading,
    next,
    data: iterations,
    previousData,
    hasFetched
  },
  actions: {
    loadMore
  },
  onClick,
  openModalOnClick
}) => {
  if (loading && !hasFetched) {
    return _jsxs(_Fragment, {
      children: [_jsx(SkeletonSearchCard, {
        icon: "Iteration",
        kind: "blue"
      }), _jsx(SkeletonSearchCard, {
        icon: "Iteration",
        kind: "blue"
      }), _jsx(SkeletonSearchCard, {
        icon: "Iteration",
        kind: "blue"
      })]
    });
  }
  const resultsList = loading ? previousData : iterations;
  if (hasFetched && !resultsList.length) {
    return _jsx(NoResults, {
      entityType: "Iterations"
    });
  }
  return _jsxs(_Fragment, {
    children: [resultsList.map((iteration, index) => _jsx(IterationSearchCardAdapter, {
      iteration: iteration,
      onClick: entity => onClick?.(entity, index),
      openModalOnClick: openModalOnClick
    }, iteration.id)), next && _jsx(LoadMore, {
      loading: loading,
      loadMore: loadMore
    })]
  });
};