import { Icon } from '@clubhouse/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { ContextMenuItem } from '../ContextMenu';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
function TaskContextMenuItem({
  onClick,
  icon,
  tooltip
}) {
  return _jsx(Tooltip, {
    content: tooltip,
    children: _jsx(ContextMenuItem, {
      onClick: onClick,
      children: _jsx(DeprecatedIconAdapter, {
        width: "15",
        children: _jsx(Icon, {
          icon: icon
        })
      })
    })
  });
}
TaskContextMenuItem.displayName = "TaskContextMenuItem";
export function UpdateTaskOwnersContextMenuItem({
  onClick
}) {
  return _jsx(TaskContextMenuItem, {
    icon: "User",
    tooltip: "Add or remove owners to this task",
    onClick: onClick
  });
}
UpdateTaskOwnersContextMenuItem.displayName = "UpdateTaskOwnersContextMenuItem";
export function ConvertTaskToStoryContextMenuItem({
  onClick
}) {
  return _jsx(TaskContextMenuItem, {
    icon: "Story",
    tooltip: "Convert to Story",
    onClick: onClick
  });
}
ConvertTaskToStoryContextMenuItem.displayName = "ConvertTaskToStoryContextMenuItem";
export function DeleteTaskContextMenuItem({
  onClick
}) {
  return _jsx(TaskContextMenuItem, {
    icon: "Trash",
    tooltip: "Delete this Task",
    onClick: onClick
  });
}
DeleteTaskContextMenuItem.displayName = "DeleteTaskContextMenuItem";