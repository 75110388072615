import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { getDefaultLabelColor } from 'data/entity/label';
import { LabelChip } from './components';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const LabelChips = ({
  labels
}) => {
  if (!labels?.length) return null;
  return _jsx(_Fragment, {
    children: labels.map(({
      id,
      name,
      color
    }) => _jsx(LabelChip, {
      color: color || getDefaultLabelColor(),
      name: name
    }, id))
  });
};