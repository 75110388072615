import { QueryStoryCardTitleSubtaskIndicatorDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useQuery } from 'gql';
import { useContext } from 'react';
import { FEATURE_TOGGLES, useVariation } from '@clubhouse/feature-toggles';
import { StoryContext } from 'components/shared/StoryCard/contexts';
import { getStorySubtaskIndicatorState } from 'components/stories/StorySubtasks/utils';
import { StoryCardInternal } from '../../internal';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const QUERY_STORY_CARD_TITLE_SUBTASK_INDICATOR = QueryStoryCardTitleSubtaskIndicatorDocument;
export const Title = () => {
  const story = useContext(StoryContext);
  const {
    value: isSubtasksEnabled
  } = useVariation(FEATURE_TOGGLES.ENABLE_SUBTASKS);
  const {
    data
  } = useQuery(QUERY_STORY_CARD_TITLE_SUBTASK_INDICATOR, {
    variables: {
      // `story` can't be undefined here as we are skipping if that's the case.
      storyId: story.global_id
    },
    skip: !story || !isSubtasksEnabled
  });
  if (!story) return null;
  const storySubtaskIndicatorState = data?.node?.__typename === 'Story' ? getStorySubtaskIndicatorState(data.node) : null;
  return _jsx(StoryCardInternal.Title, {
    isArchived: story.archived,
    name: story.name,
    storySubtaskIndicatorState: storySubtaskIndicatorState
  });
};
Title.displayName = "Title";