import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { getVelocityConfig } from 'data/entity/report';
import { getCurrentPage, getParamFromUrl, updateParamInUrl } from 'utils/navigation';
import { sendVelocityEvent } from 'utils/segment';
import { usesIterations } from 'utils/tests';
import { ReportFilterDropdown } from '../../shared/reports/ReportFilterDropdown';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const Filters = ({
  onChange
}) => {
  const filters = [];
  ['vc_aggregate', 'vc_group_by'].forEach(key => {
    if (getCurrentPage() === 'reports' && key === 'vc_group_by') return;
    const {
      defaultValue,
      title,
      values
    } = getVelocityConfig(key);
    let items = values;
    let value = getParamFromUrl(key) || defaultValue;
    if (key === 'vc_group_by' && !usesIterations()) {
      items = items.filter(v => v.value !== 'iteration');
      if (value === 'iteration') {
        value = defaultValue;
        updateParamInUrl(key, defaultValue);
        onChange();
      }
    }
    filters.push(_jsx(ReportFilterDropdown, {
      configId: key,
      value: value,
      title: title,
      onChange: (configId, selection) => {
        sendVelocityEvent({
          configId,
          selection
        });
        updateParamInUrl(configId, selection);
        onChange();
      },
      items: items
    }, key));
  });
  return _jsx(_Fragment, {
    children: filters
  });
};