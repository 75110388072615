import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { ConvertToSubtaskMutationDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { Nouns } from '@clubhouse/shared/constants';
import { useStoryCreatedToast } from 'components/stories/NewStoryInput/hooks/useStoryCreatedToast';
import { isUsingPoints } from 'data/entity/estimateScale';
import { useMutation } from 'gql';
import { ExpectedError } from 'gql/utils/ExpectedError';
import { logError } from 'utils/monitoring';
import { useWorkspaceSlug } from 'utils/navigation';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
function subtaskConversionError(error) {
  if (!(error instanceof ExpectedError)) {
    logError(new Error('Unable to convert to a Sub-task', {
      cause: error
    }));
  }
  return addToast({
    kind: 'alert',
    width: 400,
    timeout: 5000,
    Content: () => _jsx(ToastText, {
      children: (error instanceof ExpectedError ? error.message : null) || `Unable to convert to a ${Nouns.Subtask.singular}`
    })
  });
}
const ConvertToSubtaskMutation = ConvertToSubtaskMutationDocument;
export function useConvertToSubtasks({
  setIsLoading
}) {
  const workspaceSlug = useWorkspaceSlug();
  const usesPoints = isUsingPoints();
  const [convertToSubtask] = useMutation(ConvertToSubtaskMutation);
  const {
    showStoryCreatedToast
  } = useStoryCreatedToast();
  return useCallback(async ({
    input
  }) => {
    setIsLoading(true);
    try {
      const mutationResponse = await convertToSubtask({
        variables: {
          workspace2Slug: workspaceSlug,
          isUsingPoints: usesPoints,
          input
        }
      });
      const result = mutationResponse.data?.subTaskBulkCreateFromLegacyTasks;
      if (!result) {
        throw new Error('Unexpected result');
      }
      const isBulkOperation = Array.isArray(input) && input.length > 1;
      switch (result.__typename) {
        case 'SubTaskBulkCreatePayloads':
          {
            // We don't do anything here, we break out of the switch to handle the payloads.
            break;
          }
        case 'LegacyTasksNotFound':
        case 'LegacyTasksNotInSameStory':
        case 'TooManyInputs':
          throw new ExpectedError(result.message);
        default:
          {
            result;
            // We want to catch new GraphQL errors not yet known by the client.
            throw new ExpectedError(result?.message);
          }
      }
      result.payloads.forEach(payload => {
        try {
          if (!payload) {
            throw new Error('Unexpected result');
          }
          switch (payload.__typename) {
            case 'SubTaskCreatePayloadSuccess':
              {
                // If this is a bulk operation, we do a single toast at the end.
                if (!isBulkOperation) {
                  showStoryCreatedToast(payload.subTask, {
                    isSubTask: true
                  });
                }
                break;
              }
            case 'SubTaskCannotHaveSubTask':
            case 'SubTasksLimitReached':
              throw new ExpectedError(payload.message);
            default:
              {
                payload;
                // We want to catch new GraphQL errors not yet known by the client.
                throw new ExpectedError(result?.message);
              }
          }
        } catch (error) {
          // We only want to remove the loading state if the `input` isn't an array or if it's an array
          // with only one element, otherwise, it means the whole list is in a loading state
          // and we should do it at the end, when the promise is resolved.
          if (!isBulkOperation) {
            setIsLoading(false);
          }
          subtaskConversionError(error);
        }
      });
      if (isBulkOperation) {
        const successCount = result.payloads.filter(payload => payload?.__typename === 'SubTaskCreatePayloadSuccess').length;
        if (successCount) {
          addToast({
            kind: 'success',
            width: 400,
            timeout: 5000,
            Content: () => _jsxs(ToastText, {
              children: [successCount, " ", successCount === 1 ? 'task was' : 'tasks were', " successfully converted to", ' ', Nouns.Subtask.plural]
            })
          });
        }
      }
    } catch (error) {
      setIsLoading(false);
      subtaskConversionError(error);
    }

    // We never remove the loading state on success because we will be deleting the task.
  }, [usesPoints, convertToSubtask, workspaceSlug, setIsLoading, showStoryCreatedToast]);
}