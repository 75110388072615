import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { useCallback } from 'react';
import { NONE_VALUE } from '@clubhouse/shared/constants';
import { insertIf } from '@clubhouse/shared/utils/object';
import { useLazyQuery } from 'gql';
import { useLazyWorkspaceCurrentPermissionIdQuery } from 'gql/queries/useWorkspaceCurrentPermissionIdQuery';
import { useWorkspaceSlug } from 'utils/navigation';
export function useFilterOptionsQueryWithCurrentPermissionId({
  query,
  selectedIds
}) {
  const slug = useWorkspaceSlug();
  const [executeQuery] = useLazyQuery(query);
  const lazyCurrentPermission = useLazyWorkspaceCurrentPermissionIdQuery();
  return useCallback(async ({
    inputValue
  }) => {
    const {
      data
    } = await executeQuery({
      variables: {
        workspaceSlug: slug,
        input: {
          currentPermissionId: (await lazyCurrentPermission()) ?? '',
          selectedIds: selectedIds.filter(value => value !== NONE_VALUE),
          ...insertIf(Boolean(inputValue), {
            fuzzyName: inputValue?.trim()
          })
        }
      }
    });
    return data?.workspace2?.options;
  }, [executeQuery, lazyCurrentPermission, selectedIds, slug]);
}