import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { GetCompletedSubtasksCountStoryConnectionFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { WORKFLOW_STATE_TYPES } from '@clubhouse/shared/constants';
export const GetCompletedSubtasksCountStoryConnectionFragment = GetCompletedSubtasksCountStoryConnectionFragmentDoc;
export function getCompletedSubtasksCount(subtasks) {
  if (!subtasks) return {};
  const totalSubtasks = subtasks.filter(edge => !edge.node.archived).length;
  const completedSubtasksCount = subtasks.filter(edge => edge.node.workflowState.type === WORKFLOW_STATE_TYPES.DONE && !edge.node.archived).length;
  return {
    totalSubtasks,
    completedSubtasksCount
  };
}