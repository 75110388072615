import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { Nouns } from '@clubhouse/shared/constants';
import { useConvertToSubtasks } from '../useConvertToSubtasks';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function ConvertToSubtaskAction({
  taskGlobalId,
  setIsLoading,
  isLoading
}) {
  const convertToSubTasks = useConvertToSubtasks({
    setIsLoading
  });
  return _jsxs(MoreActions.Item, {
    icon: "Stack",
    isDisabled: isLoading,
    onClick: () => {
      convertToSubTasks({
        input: {
          legacyTask: taskGlobalId
        }
      });
    },
    children: ["Convert to ", Nouns.Subtask.singular]
  });
}
ConvertToSubtaskAction.displayName = "ConvertToSubtaskAction";