import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import { BulkEditFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useEffect, useState } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { Checkbox } from '@clubhouse/shared/components/Checkbox';
import { emptyArray } from '@clubhouse/shared/utils';
import { useBulkEdit, useGroupBulkEdit } from '../../hooks/useTableBulkSelection';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const BulkEditFieldFragment = BulkEditFieldFragmentFragmentDoc;
const BulkEditFieldComponent = ({
  entity: {
    publicId: id
  }
}) => {
  const {
    isSelected,
    select,
    unselect
  } = useBulkEdit(id);
  return _jsx(Center, {
    children: _jsx(Checkbox, {
      isSelected: isSelected,
      onChange: (_, event) => {
        if (event.shiftKey) document.getSelection()?.removeAllRanges();
        if (isSelected) unselect(event.shiftKey);else select(event.shiftKey);
      }
    })
  });
};
BulkEditFieldComponent.displayName = "BulkEditFieldComponent";
const mapAndFilterIds = items => items.map(item => item?.publicId).filter(id => typeof id === 'number');
const HeaderComponent = ({
  groupItems = emptyArray
}) => {
  const [groupItemIds, setGroupItemIds] = useState(() => {
    if (typeof groupItems === 'function') return [];
    return mapAndFilterIds(groupItems);
  });
  useEffect(() => {
    if (typeof groupItems === 'function') groupItems().then(result => setGroupItemIds(mapAndFilterIds(result)));else setGroupItemIds(mapAndFilterIds(groupItems));
  }, [groupItems]);
  const {
    selectAll,
    unselectAll,
    isSelected
  } = useGroupBulkEdit(groupItemIds);
  return _jsx(Center, {
    children: _jsx(Checkbox, {
      isDisabled: groupItemIds.length === 0,
      isSelected: isSelected,
      onChange: () => {
        if (isSelected) unselectAll();else selectAll();
      }
    })
  });
};
HeaderComponent.displayName = "HeaderComponent";
export const BulkEditField = ({
  frozenOverride
} = {}) => ({
  name: 'selection',
  displayName: '',
  HeaderComponent,
  width: 50,
  maxWidth: 50,
  sort: false,
  headerProps: {
    centered: true
  },
  Component: BulkEditFieldComponent,
  frozen: frozenOverride || {
    left: 25
  }
});