import { useCallback } from 'react';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { Nouns } from '@clubhouse/shared/constants';
import { StoryAutoLink } from 'components/shared/StoryAutoLink';
import { useStoryConfirmationDialog } from '../hooks/useStoryConfirmationDialog';
import { useUpdateParentStoryMutation } from '../hooks/useUpdateParentStoryMutation';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const showToast = (success, message) => {
  addToast({
    kind: success ? 'success' : 'alert',
    width: 400,
    timeout: 5000,
    Content: () => _jsx(ToastText, {
      children: message
    })
  });
};
export const DetachFromParentAction = ({
  story
}) => {
  const {
    openDialog,
    closeDialog
  } = useStoryConfirmationDialog();
  const updateParentStory = useUpdateParentStoryMutation();
  const detachFromParent = useCallback(() => {
    openDialog('detachFromParent', {
      onClick: async () => {
        try {
          closeDialog();
          await updateParentStory({
            storyId: story.id,
            parentStory: null
          });
          showToast(true, _jsxs(_Fragment, {
            children: [Nouns.Subtask.singular, ' ', _jsx("strong", {
              children: _jsx(StoryAutoLink, {
                id: story.id,
                publicId: story.publicId,
                name: story.name,
                type: story.type
              })
            }), ' ', "was converted to a Story."]
          }));
        } catch {
          showToast(false, _jsxs(_Fragment, {
            children: ["Unable to convert", ' ', _jsx("strong", {
              children: _jsx(StoryAutoLink, {
                id: story.id,
                publicId: story.publicId,
                name: story.name,
                type: story.type
              })
            }), "."]
          }));
        }
      }
    });
  }, [closeDialog, openDialog, story, updateParentStory]);
  return _jsx(MoreActions.Item, {
    icon: "Story",
    onClick: detachFromParent,
    children: "Detach from Parent"
  });
};
DetachFromParentAction.displayName = "DetachFromParentAction";