import { makeVar, useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';
import * as LocalStorage from 'utils/localStorage';
const FEEDBACK_VAR = 'feedbackVar';
const RESET_STATE = {
  isOpen: false,
  subject: '',
  body: ''
};
const makeFeedbackVar = () => makeVar(LocalStorage.getValue(FEEDBACK_VAR) ?? {
  isOpen: false,
  subject: '',
  body: ''
});
let feedbackVar;
export const useOpenFeedbackForm = () => {
  if (!feedbackVar) feedbackVar = makeFeedbackVar();
  const isOpen = useReactiveVar(feedbackVar).isOpen;
  const subject = useReactiveVar(feedbackVar).subject;
  const body = useReactiveVar(feedbackVar).body;
  const openFeedbackForm = useCallback(force => {
    const newValue = {
      subject: feedbackVar().subject,
      body: feedbackVar().body,
      isOpen: force ?? !isOpen
    };
    feedbackVar(newValue);
    LocalStorage.setValue(FEEDBACK_VAR, newValue);
  }, [isOpen]);
  const setSubject = useCallback(newSubject => {
    const newValue = {
      subject: newSubject,
      body: feedbackVar().body,
      isOpen: feedbackVar().isOpen
    };
    feedbackVar(newValue);
    LocalStorage.setValue(FEEDBACK_VAR, newValue);
  }, []);
  const setBody = useCallback(newBody => {
    const newValue = {
      body: newBody,
      subject: feedbackVar().subject,
      isOpen: feedbackVar().isOpen
    };
    feedbackVar(newValue);
    LocalStorage.setValue(FEEDBACK_VAR, newValue);
  }, []);
  const resetPersistedFormState = useCallback(() => {
    feedbackVar(RESET_STATE);
    LocalStorage.setValue(FEEDBACK_VAR, RESET_STATE);
  }, []);
  return {
    isOpen,
    openFeedbackForm,
    setSubject,
    subject,
    setBody,
    body,
    resetPersistedFormState
  };
};