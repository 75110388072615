import { MutateStoryDeadlineDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { StoryDeadlineFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { TableDatePicker } from 'components/shared/table/base';
import { useMutation } from 'gql';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const StoryDeadlineFieldFragment = StoryDeadlineFieldFragmentFragmentDoc;
const MUTATE_STORY_DEADLINE = MutateStoryDeadlineDocument;
function DeadlineFieldComponent({
  entity: {
    id,
    deadline,
    workflowState
  }
}) {
  const [save] = useMutation(MUTATE_STORY_DEADLINE);
  const handleChange = useCallback(date => {
    save({
      variables: {
        id,
        input: {
          deadline: date
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        storyUpdate: {
          __typename: 'StoryPayload',
          story: {
            __typename: 'Story',
            id,
            deadline: date
          }
        }
      }
    });
  }, [id, save]);
  return _jsx(TableDatePicker, {
    onChange: handleChange,
    value: deadline,
    colorizeDueDate: workflowState.type !== 'done'
  });
}
DeadlineFieldComponent.displayName = "DeadlineFieldComponent";
export const DeadlineField = () => ({
  name: 'deadline',
  displayName: 'Due Date',
  Component: DeadlineFieldComponent,
  width: 140,
  sort: true,
  LoadingComponent: ({
    animate
  }) => _jsx(Center, {
    children: _jsx(SkeletonRectangle, {
      width: 40,
      animate: animate
    })
  }),
  headerProps: {
    centered: true
  }
});