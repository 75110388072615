import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.some.js";
import { matchPath } from 'react-router-dom';
import { PAGE_NAMES, getPathForPage } from 'utils/navigation/paths';
export const TeamScopedPages = {
  stories: {
    list: PAGE_NAMES.STORIES,
    detail: PAGE_NAMES.STORY
  },
  backlog: {
    list: PAGE_NAMES.BACKLOG
  },
  iterations: {
    list: PAGE_NAMES.ITERATIONS,
    detail: PAGE_NAMES.ITERATION
  },
  epics: {
    list: PAGE_NAMES.EPICS,
    detail: PAGE_NAMES.EPIC
  },
  status: {
    list: PAGE_NAMES.STATUS
  }
};
export const isTeamScopedListPage = pathname => {
  return isTeamScopedPageByPageType('list', pathname);
};
export const isTeamScopedDetailPage = pathname => {
  return isTeamScopedPageByPageType('detail', pathname);
};
export const isTeamScopedPage = pathname => isTeamScopedDetailPage(pathname) || isTeamScopedListPage(pathname);
const isTeamScopedPageByPageType = (pageType, pathname) => {
  return Object.values(TeamScopedPages).some(obj => pageType in obj && matchPath(pathname, {
    path: getPathForPage(obj[pageType])
  }));
};