import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { useCallback, useMemo } from 'react';
import { useInlineEditing } from '@clubhouse/shared/components/Table';
import { TableTeam } from 'components/shared/table/base';
import { FIELD_ID } from 'components/shared/table/types';
import { byGroupNameSortFn, all as getAllGroups, getById as getGroupById, isActiveGroup as isActiveTeam } from 'data/entity/group';
import { getAllActiveProfileDetails, getCurrentUserProfileDetails } from 'data/entity/profile';
import { saveChanges } from 'data/entity/story';
import { getWorkflowUpdates } from './utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TeamFieldComponent = ({
  entity
}) => {
  const [selectedTeam, saveChange] = useInlineEditing(entity.group_id, groupId => {
    let changes = {
      group_id: groupId ? String(groupId) : null
    };
    const group = groupId ? getGroupById(groupId) : undefined;
    if (group && (!entity?.workflow_id || !group.workflow_ids.includes(entity.workflow_id))) {
      const workflowId = group.workflow_ids[0];
      const workflowUpdates = getWorkflowUpdates({
        entity,
        workflowId: Number(workflowId)
      });
      changes = {
        ...changes,
        ...workflowUpdates
      };
    }
    return saveChanges(entity.id, changes);
  });
  const currentUser = useMemo(() => getCurrentUserProfileDetails(), []);
  const users = useMemo(() => getAllActiveProfileDetails(), []);
  const teams = useMemo(() => getAllGroups(), []);
  const activeTeams = teams.filter(team => isActiveTeam(team) || selectedTeam === team.id);
  const handleChange = useCallback(groupIds => saveChange(groupIds[0]), [saveChange]);
  return _jsx(TableTeam, {
    currentUser: currentUser,
    onChange: handleChange,
    selectedValues: selectedTeam ? [selectedTeam] : [],
    teams: activeTeams,
    users: users
  });
};
TeamFieldComponent.displayName = "TeamFieldComponent";
export const TeamField = () => ({
  name: FIELD_ID.TEAM,
  displayName: 'Team',
  sort: byGroupNameSortFn,
  headerProps: {
    centered: true
  },
  Component: TeamFieldComponent,
  width: 60
});