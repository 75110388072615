import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.every.js";
import "core-js/modules/esnext.iterator.reduce.js";
export function getSubtasksTotalPoints(subtasks, usesPoints) {
  if (!usesPoints || !subtasks) return;

  // Check whether all of the subtasks(stories) have an estimate val of `null`(unset estimate).
  if (subtasks.edges.every(edge => edge.node.estimate === null)) return;
  return subtasks.edges.reduce((acc, curr) => {
    if (typeof curr.node.estimate !== 'number' || curr.node.archived) return acc;
    return acc + curr.node.estimate;
  }, 0);
}