import { Button } from '@clubhouse/shared/components/Button';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { ConvertAllToSubtasksAction } from './ConvertAllToSubtasksAction';
import { DeleteAllTasksActions } from './DeleteAllTasksAction';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function LegacyTasksActionMenu() {
  return _jsxs(MoreActions, {
    children: [_jsx(MoreActions.Trigger, {
      asChild: true,
      children: _jsx(Button, {
        kind: "ghost",
        fit: "exact",
        size: "small",
        children: _jsx(SizedIcon, {
          size: 18,
          icon: "More",
          label: "Open actions menu"
        })
      })
    }), _jsxs(MoreActions.Menu, {
      children: [_jsx(ConvertAllToSubtasksAction, {}), _jsx(DeleteAllTasksActions, {})]
    })]
  });
}
LegacyTasksActionMenu.displayName = "LegacyTasksActionMenu";