import { useBreakpoints } from '@clubhouse/shared/hooks';
import { SideDrawer } from 'components/shared/SideDrawer';
import { useRef } from 'react';
import { FeedbackForm } from './FeedbackForm';
import { useOpenFeedbackForm } from './useOpenFeedbackForm';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const FeedbackSidebar = () => {
  const pushDrawer = useBreakpoints({
    min: 'xlargeDesktop'
  });
  const {
    openFeedbackForm,
    setSubject,
    setBody,
    isOpen
  } = useOpenFeedbackForm();
  const handeClose = () => {
    setSubject('');
    setBody('');
    openFeedbackForm(false);
  };
  const triggerRef = useRef(null);
  return _jsx(SideDrawer, {
    title: "Talk to us!",
    triggerRef: triggerRef,
    isOpen: isOpen,
    onClose: handeClose,
    size: "small",
    type: pushDrawer ? 'push' : 'overlay',
    children: _jsx(FeedbackForm, {})
  });
};
FeedbackSidebar.displayName = "FeedbackSidebar";