import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import { uniqBy } from 'lodash';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { moveWithinArray } from '@clubhouse/shared/utils/reorder';
import { useApplicationStateByKey, useUpdateApplicationState } from 'gql';
import { logAddItem, logRemoveItem } from '../logging/logging';
import { applicationStateKey, createPinnedState } from './pinnedState';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const useReorderPinnedItems = ({
  updateQuery
}) => {
  const {
    update
  } = useUpdateApplicationState({
    applicationStateKey,
    toExpectedType: createPinnedState,
    updateQuery
  });
  return ({
    fromIndex,
    toIndex
  }) => update(data => {
    moveWithinArray(data.pinnedItems, fromIndex, toIndex);
    return data;
  });
};
export const useAddPinnedItem = () => {
  const {
    update
  } = useUpdateApplicationState({
    applicationStateKey,
    toExpectedType: createPinnedState
  });
  return async ({
    id,
    loggingContext
  }) => {
    logAddItem(loggingContext);
    try {
      return await update(data => {
        const pinnedItems = uniqBy([...data.pinnedItems, {
          id
        }], 'id');
        if (pinnedItems.length > 20) {
          addToast({
            kind: 'alert',
            Content: () => _jsx(ToastText, {
              children: "The number of pins is limited to 20."
            }),
            timeout: 5000
          });
          throw new Error('pinnedItem length exceeded');
        }
        data.pinnedItems = pinnedItems;
        return data;
      });
    } catch (e) {}
  };
};
export const useRemovePinnedItem = () => {
  const {
    update
  } = useUpdateApplicationState({
    applicationStateKey,
    toExpectedType: createPinnedState
  });
  return ({
    id,
    loggingContext
  }) => {
    logRemoveItem(loggingContext);
    return update(data => {
      return {
        ...data,
        pinnedItems: data.pinnedItems.filter(item => item.id !== id)
      };
    });
  };
};
export const usePinnedItem = ({
  id,
  loggingContext
}) => {
  const {
    pinnedItems
  } = usePinnedItems();
  const addPinnedItem = useAddPinnedItem();
  const removePinnedItem = useRemovePinnedItem();
  const isPinned = !!pinnedItems?.find(item => item.id === id);
  return {
    isPinned,
    toggle: () => isPinned ? removePinnedItem({
      id,
      loggingContext
    }) : addPinnedItem({
      id,
      loggingContext
    })
  };
};
export const usePinnedItems = ({
  fetchPolicy
} = {}) => {
  const {
    data,
    updateQuery,
    loading,
    refetch
  } = useApplicationStateByKey({
    applicationStateKey,
    toExpectedType: createPinnedState,
    fetchPolicy
  });
  return {
    pinnedItems: data?.pinnedItems,
    updateQuery,
    loading,
    refetch
  };
};