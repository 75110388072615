import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { useState } from 'react';
import { StoryTooltipInline } from 'components/gql/stories/StoryTooltip';
import { PAGE_NAMES, generatePathForPage, getCurrentSlug, navigateTo } from 'utils/navigation';
import { getWindow } from '../utils/windowAdapter';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
getWindow().mermaidStoryTooltipClickHandler = storyId => {
  if (!storyId) {
    throw new Error('Story ID is missing');
  }
  navigateTo({
    url: generatePathForPage(PAGE_NAMES.STORY, {
      slug: getCurrentSlug(),
      id: storyId
    })
  });
};

/**
 * Used to display custom tooltips in Mermaid diagrams.
 *
 * Mermaid doesn't support custom tooltips at the moment. The only things we
 * can do with tooltips are custom text (obviously) and custom styling via CSS.
 *
 * We need to use this container to bypass this and allow us to display custom
 * tooltips (we want to render a React component).
 *
 * This container will listen to mouse events on Mermaid nodes, read the
 * tooltip's title attribute (the story ID), and render the appropriate tooltip
 * component.
 */
export function StoryTooltipContainer({
  mermaidClassName
}) {
  const [tooltipContent, setTooltipContent] = useState(null);
  const [position, setPosition] = useState({
    x: 0,
    y: 0
  });
  const mermaidContainer = document.querySelector(`.${mermaidClassName}`);
  if (mermaidContainer) {
    // Add event listeners to nodes for tooltips
    const nodes = mermaidContainer.querySelectorAll('.node');
    nodes.forEach(node => {
      node.addEventListener('mouseover', e => {
        const tooltip = e.currentTarget.getAttribute('title');
        if (tooltip) {
          setTooltipContent(tooltip);

          // Update tooltip position
          const rect = e.currentTarget.getBoundingClientRect();
          setPosition({
            x: rect.x + rect.width / 2,
            y: rect.y
          });
        }
      });
      node.addEventListener('mouseout', () => {
        setTooltipContent(null);
      });
    });
  }
  if (!tooltipContent) {
    return null;
  }
  return _jsx("div", {
    style: {
      position: 'absolute',
      left: `${position.x}px`,
      top: `${position.y}px`,
      pointerEvents: 'none'
    },
    children: _jsx(StoryTooltipInline, {
      storyId: tooltipContent
    })
  });
}
StoryTooltipContainer.displayName = "StoryTooltipContainer";