import { CustomFieldIcon, Icon, SeverityIcon } from '@clubhouse/shapes-ds';
import { getTint } from '@clubhouse/shared/color';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { SEVERITY_ICONS } from '@clubhouse/shared/utils/customFields';
import { isCompatibleCustomFieldIconSet } from 'utils/customField';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const serializeCustomField = fieldId => valueId => `${fieldId}@${valueId}`;
export const deserializeCustomField = fieldAndValue => {
  const [fieldId, valueId] = fieldAndValue.split('@');
  return {
    fieldId,
    valueId
  };
};
export function getIcon(canonicalName, colorKey, position, iconSetIdentifier) {
  if (canonicalName === 'severity' && typeof position === 'number') {
    return () => _jsx(DeprecatedIconAdapter, {
      children: _jsx(SeverityIcon, {
        icon: SEVERITY_ICONS[position]
      })
    });
  }
  if (canonicalName === 'productArea') {
    return () => _jsx(DeprecatedIconAdapter, {
      fill: getTint(colorKey),
      children: _jsx(Icon, {
        icon: "FeatureArea"
      })
    });
  }
  if (canonicalName === 'technicalArea') {
    return () => _jsx(DeprecatedIconAdapter, {
      fill: getTint(colorKey),
      children: _jsx(Icon, {
        icon: "TechnicalArea"
      })
    });
  }
  if (canonicalName === 'skillSet') {
    return () => _jsx(DeprecatedIconAdapter, {
      fill: getTint(colorKey),
      children: _jsx(Icon, {
        icon: "Skillset"
      })
    });
  }
  if (isCompatibleCustomFieldIconSet(iconSetIdentifier)) {
    return () => _jsx(DeprecatedIconAdapter, {
      fill: getTint(colorKey),
      children: _jsx(CustomFieldIcon, {
        icon: iconSetIdentifier
      })
    });
  }
  return () => _jsx(DeprecatedIconAdapter, {
    fill: getTint(colorKey),
    children: _jsx(Icon, {
      icon: "Field"
    })
  });
}