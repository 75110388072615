import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { useCallback, useEffect, useRef, useState } from 'react';
import { escapeRegex, parseOutsideMarkdownCodeBlocks } from 'utils/format';
import * as Snapshot from 'utils/snapshot';
import { FEATURE_TOGGLES, useVariation } from '@clubhouse/feature-toggles';
import { isInUrl } from '@clubhouse/shared/utils/url';
import { EMOJI_PREFIX, EmojiAutocomplete } from './emojis';
import { LinksAutocomplete, URL_PREFIX } from './links';
import { MENTION_PREFIX, MentionAutocomplete, buildAllMappings, convertBackToRawText } from './mentions';
import { SEARCH_PREFIX_HASH, SEARCH_PREFIX_SLASH, SearchAutocomplete } from './search';
import { TABLE_FNS_PREFIX, TableAutocomplete } from './table';
import { ensureFullMatch } from './utils';
const putSnapshot = (key, state) => {
  if (!key || !state) return;
  if (state.text) {
    Snapshot.putSnapshot(key, state);
  } else {
    Snapshot.clearSnapshot(key);
  }
};
const PREFIXES = [MENTION_PREFIX, EMOJI_PREFIX, SEARCH_PREFIX_SLASH, SEARCH_PREFIX_HASH, URL_PREFIX, TABLE_FNS_PREFIX];
const AUTOCOMPLETES = [
// TODO: MentionMenu and related logic should be using GQL to remove the coupling to consolidated fetch.
MentionAutocomplete, SearchAutocomplete, EmojiAutocomplete, LinksAutocomplete, TableAutocomplete];
const getAutocompletableTextFragment = (text, caret) => {
  let c,
    foundPrefix,
    iEnd,
    iStart,
    range = '';

  // First: Move to end of current word
  for (iEnd = caret; iEnd < text.length; ++iEnd) {
    c = text.charAt(iEnd);
    if (/[a-zA-Z0-9\-_\(\)\u00C0-\u017F]/.test(c)) range = range + c;else break;
  }

  // Second: Move forward until we find a prefix or whitespace
  for (iStart = caret - 1; iStart >= 0; --iStart) {
    c = text.charAt(iStart);
    if (/\s/.test(c)) break;
    range = c + range;
    foundPrefix = PREFIXES.find(prefix => typeof prefix === 'string' ? range.startsWith(prefix) : prefix.test(range));

    // If prefix is found, but not at the beginning of the word, keep looking
    if (foundPrefix && (iStart === 0 || /\s/.test(text.charAt(iStart - 1)))) return {
      text: range,
      start: iStart,
      end: iEnd
    };
  }
  return null;
};
const createDefault = ({
  text = ''
} = {}) => ({
  mappings: {},
  text: text,
  height: 0,
  selectionStart: text.length,
  selectionEnd: text.length,
  scrollPos: 0
});
export const useAutocomplete = ({
  textareaRef,
  initialText,
  snapshotKey,
  onTextChange,
  restoreHeight = true,
  updateSnapshot = true,
  takeFocus = true
}) => {
  const {
    value: showDrafts
  } = useVariation(FEATURE_TOGGLES.ENABLE_STORY_DRAFTS_IN_NAV);
  const stateRef = useRef(null);
  const [current, setCurrent] = useState(null);
  const updateState = useCallback(() => {
    if (!stateRef.current) stateRef.current = createDefault();
    stateRef.current.text = textareaRef.current?.value ?? '';
    stateRef.current.height = textareaRef.current?.offsetHeight ?? 0;
    stateRef.current.selectionStart = textareaRef.current?.selectionStart ?? 0;
    stateRef.current.selectionEnd = textareaRef.current?.selectionEnd ?? 0;
    stateRef.current.scrollPos = textareaRef.current?.scrollTop ?? 0;
    if (updateSnapshot) putSnapshot(snapshotKey, stateRef.current);
  }, [snapshotKey, textareaRef, updateSnapshot]);
  const getFormattedText = useCallback((text = stateRef.current?.text ?? '') => {
    buildAllMappings(text, stateRef);
    const {
      mappings
    } = stateRef.current || createDefault();
    const formattedText = Object.keys(mappings).reduce((t, from) => {
      return parseOutsideMarkdownCodeBlocks(t, chunk => {
        const pattern = new RegExp('\\B' + escapeRegex(from) + '\\b', 'g');
        let match = pattern.exec(chunk);
        while (match) {
          const isUrl = isInUrl(chunk, match.index);
          if (!isUrl && ensureFullMatch(from, chunk, match.index)) {
            chunk = chunk.substring(0, match.index) + mappings[from] + chunk.substring(match.index + from.length);
            pattern.lastIndex = match.index + mappings[from].length;
          }
          match = pattern.exec(chunk);
        }
        return chunk;
      });
    }, text);
    return formattedText;
  }, []);
  const findFragment = useCallback(({
    caret,
    value
  }) => {
    const fragment = getAutocompletableTextFragment(value, caret);
    if (!fragment) {
      setCurrent(null);
      updateState();
      return false;
    }
    const {
      text,
      start,
      end
    } = fragment;
    const match = AUTOCOMPLETES.find(autocomplete => autocomplete.test(text, {
      start,
      end,
      value
    }));
    if (match) {
      setCurrent({
        createMapping: match.createMapping,
        fragment: match.hasPrefix ? text.slice(1) : text,
        cursor: caret,
        fragmentStart: start,
        fragmentEnd: end,
        Component: match.Component
      });
    } else setCurrent(null);
    updateState();
    return true;
  }, [updateState]);
  const forceClose = useCallback(() => {
    setCurrent(null);
  }, []);
  const onKeyUp = useCallback(e => {
    if (e.key === 'Escape') {
      if (current) e.preventDefault();
      return forceClose();
    }
    const caret = e.target.selectionStart;
    const value = e.target.value;
    findFragment({
      caret,
      value
    });
  }, [current, findFragment, forceClose]);
  const onSelect = useCallback(val => {
    if (!current) return;
    const {
      createMapping,
      fragmentStart,
      fragmentEnd
    } = current;
    let output = createMapping ? createMapping(val, stateRef) : val;
    if (!output) return;
    forceClose();
    output += ' ';
    if (textareaRef.current) {
      const text = textareaRef.current.value;
      const newText = text.slice(0, fragmentStart) + output + text.slice(fragmentEnd);
      const selStart = fragmentStart + output.length;
      const scrollPos = textareaRef.current.scrollTop;
      textareaRef.current.value = newText;
      textareaRef.current.focus();
      textareaRef.current.selectionStart = textareaRef.current.selectionEnd = selStart;
      updateState();
      onTextChange(newText, {
        selectionStart: selStart,
        selectionEnd: selStart,
        scrollPos
      });
    }
  }, [current, forceClose, onTextChange, textareaRef, updateState]);

  // Set initial state
  // biome-ignore lint/correctness/useExhaustiveDependencies: initialText should not be included. It is only set on first run
  useEffect(() => {
    if (snapshotKey) {
      try {
        const snapshot = Snapshot.getSnapshot(snapshotKey);
        if (snapshot) {
          stateRef.current = {
            ...createDefault(),
            ...snapshot
          };
        }
      } catch {}
    }
    if (stateRef.current === null) {
      stateRef.current = createDefault({
        text: initialText
      });
    }
    stateRef.current.text = convertBackToRawText(stateRef.current.text);
    if (textareaRef.current) {
      textareaRef.current.value = stateRef.current.text;
      if (restoreHeight) textareaRef.current.style.height = `${stateRef.current.height}px`;
      if (takeFocus !== false) {
        textareaRef.current.focus();
        textareaRef.current.setSelectionRange(stateRef.current.selectionStart, stateRef.current.selectionEnd);
        textareaRef.current?.scrollTo(0, stateRef.current.scrollPos);
      }

      // TODO: why do we call onTextChange when this component is
      // initially mounted? This might not be needed.
      if (!showDrafts) {
        onTextChange(stateRef.current.text, {
          selectionStart: stateRef.current.selectionStart,
          selectionEnd: stateRef.current.selectionEnd,
          scrollPos: stateRef.current.scrollPos
        });
      }
    }
  }, [onTextChange, restoreHeight, snapshotKey, stateRef, textareaRef, showDrafts]);
  useEffect(() => {
    let lastSelectionStart = -1;
    const onSelection = () => {
      const {
        current: textarea
      } = textareaRef;
      if (textarea) {
        const {
          value,
          selectionStart,
          selectionEnd,
          scrollTop
        } = textarea;
        if (lastSelectionStart !== selectionStart) {
          lastSelectionStart = selectionStart;
          if (findFragment({
            caret: selectionStart,
            value
          })) onTextChange(value, {
            selectionStart,
            selectionEnd,
            scrollPos: scrollTop
          });
        }
      }
    };
    document.addEventListener('selectionchange', onSelection);
    return () => {
      document.removeEventListener('selectionchange', onSelection);
    };
  }, [findFragment, onTextChange, textareaRef]);
  return {
    fragment: current?.fragment ?? '',
    onKeyUp,
    forceClose,
    AutoCompleteMenu: current?.Component ?? null,
    onSelect,
    getFormattedText
  };
};