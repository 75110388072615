import { QueryEpicsTableRowsDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { WorkspaceHasEpicsDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import { useCallback, useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { insertIf as insertArrayIf } from '@clubhouse/shared/utils/array';
import { emptyArray } from '@clubhouse/shared/utils/emptyArray';
import { ObjectivesField } from 'components/gql/epics/table/fields/ObjectivesField';
import { useQuery } from 'gql';
import { useDataLayerUpdateEvent } from 'gql/components/updates/useDataLayerUpdateEvent';
import { useFetchMore } from 'gql/hooks/useFetchMore';
import { useWorkspaceSlug } from 'utils/navigation';
import { CreatedDateField } from './fields/CreatedDateField';
import { DeadlineField } from './fields/DeadlineField';
import { EpicHealthField } from './fields/EpicHealthField';
import { EpicStatesField } from './fields/EpicStateField';
import { KeyResultCountField, UpgradeKeyResultCountField } from './fields/KeyResultCountField';
import { LabelsField } from './fields/LabelsField';
import { LastUpdatedDateField } from './fields/LastUpdatedDateField';
import { MoreActionsField } from './fields/MoreActionsField';
import { NameField } from './fields/NameField';
import { OwnersField } from './fields/OwnersField';
import { PositionField } from './fields/PositionField';
import { ProgressField } from './fields/ProgressField';
import { PublicIdField } from './fields/PublicIdField';
import { RelatedDocsField } from './fields/RelatedDocsField';
import { StartDateField } from './fields/StartDateField';
import { TeamsOrAssociatedTeamsField } from './fields/TeamsOrAssociatedTeamsField';
import { TotalPointsField } from './fields/TotalPointsField';
import { TotalStoriesField } from './fields/TotalStoriesField';
const getColumns = ({
  estimateUnit = 'story',
  usesEstimates,
  usesDocs,
  usesObjectives,
  canCreateKeyResults
}) => [PositionField(), PublicIdField(), NameField(), TotalStoriesField(), ...insertArrayIf(usesEstimates, [TotalPointsField()]), ...insertArrayIf(usesDocs, [RelatedDocsField()]), TeamsOrAssociatedTeamsField(), OwnersField(), EpicStatesField(), ProgressField(estimateUnit), EpicHealthField(), StartDateField(), DeadlineField(), ...insertArrayIf(usesObjectives, [ObjectivesField()]), ...insertArrayIf(usesObjectives, [canCreateKeyResults ? KeyResultCountField() : UpgradeKeyResultCountField()]), LabelsField(), LastUpdatedDateField(), CreatedDateField(), MoreActionsField()];

// The exact input parameters are not important. We just want to make sure we get all columns back.
export const ALL_SORTABLE_VALUES = getColumns({
  estimateUnit: 'point',
  usesEstimates: true,
  usesDocs: true,
  usesObjectives: true,
  canCreateKeyResults: true
}).map(field => {
  if (field.sort === true) return field.name;
  if (typeof field.sort === 'string') return field.sort;
  return null;
}).filter(v => !!v);
const WorkspaceHasEpicsQuery = WorkspaceHasEpicsDocument;
const EpicsTableRowsQuery = QueryEpicsTableRowsDocument;
const useIsEmptyWorkspace = () => {
  const {
    data,
    error,
    refetch
  } = useQuery(WorkspaceHasEpicsQuery, {
    variables: {
      slug: useWorkspaceSlug()
    }
  });
  const isLoading = !error && !data;
  return {
    isLoading,
    isEmpty: isLoading ? null : data?.workspace2?.hasEpics === false,
    refetch
  };
};
export function useEpicsTableQuery(input, options) {
  const slug = useWorkspaceSlug();
  const teams = input.where?.teamsOrAssociatedTeams ?? input.where?.teams;
  const {
    isLoading: isLoadingEpicCount,
    isEmpty: isEmptyWorkspace,
    refetch: refetchEmptyWorkspace
  } = useIsEmptyWorkspace();
  const {
    data,
    error,
    refetch,
    updateQuery,
    previousData,
    isInitialDataFromCache,
    fetchMore
  } = useQuery(EpicsTableRowsQuery, {
    variables: {
      ...(teams ? {
        includeTeams: teams
      } : {}),
      input,
      slug
    },
    ...options
  });
  useDataLayerUpdateEvent(() => refetch(), {
    filter: ['Epic']
  });
  const dataToRender = data || previousData;
  const epics = useMemo(() => {
    return (dataToRender?.workspace2?.epics?.edges || emptyArray).map(edge => edge.node);
  }, [dataToRender?.workspace2?.epics?.edges]);
  const isLoading = isLoadingEpicCount || !error && !dataToRender;
  const isStale = !data && !!previousData;
  const hasNoEpicsInScope = !isLoadingEpicCount && isEmptyWorkspace;
  const usesDocs = dataToRender?.workspace2?.features.docs.enabled || false;
  const usesObjectives = !!dataToRender?.workspace2?.features.objectives.enabled;
  const usesEstimates = dataToRender?.workspace2?.canPointStories || false;
  const estimateUnit = dataToRender?.workspace2?.progressUnit;
  const canCreateKeyResults = dataToRender?.workspace2?.canCreateKeyResults;
  const columns = useMemo(() => getColumns({
    usesEstimates,
    estimateUnit,
    usesDocs,
    usesObjectives,
    canCreateKeyResults: !!canCreateKeyResults
  }), [usesEstimates, estimateUnit, usesDocs, usesObjectives, canCreateKeyResults]);
  const fetchMoreData = useFetchMore(fetchMore);
  return {
    columns,
    epics,
    pageInfo: dataToRender?.workspace2?.epics?.pageInfo ?? null,
    isLoading,
    isStale,
    error,
    refetch: useCallback((...args) => {
      refetchEmptyWorkspace();
      refetch(...args);
    }, [refetch, refetchEmptyWorkspace]),
    updateQuery,
    isInitialDataFromCache,
    data,
    previousData,
    hasNoEpicsInScope,
    ...fetchMoreData
  };
}