import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import chunk from 'lodash/chunk';
import { Avatar } from '@clubhouse/shared/components/Avatar';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { useIsMobile } from '@clubhouse/shared/hooks';
import { getAllDetailsById } from 'data/entity/profile';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export function TaskOwners({
  task
}) {
  const ownerRowSize = useIsMobile() ? 3 : 4;
  const ownerGroups = chunk(task.owner_ids, ownerRowSize);
  return _jsx(_Fragment, {
    children: ownerGroups.map((ownerGroup, i) => _jsx("span", {
      className: "owners",
      children: _jsx(Spaced, {
        horizontally: true,
        amount: 3,
        children: ownerGroup.map(ownerId => {
          const owner = getAllDetailsById(ownerId);
          return owner ? _jsx(Avatar, {
            profile: owner,
            size: Avatar.SIZE.XS,
            withTooltip: true
          }, ownerId) : null;
        })
      })
    }, i))
  });
}