import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { MutateStoryTechnicalAreaFieldDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { QueryStoryTechnicalAreaSelectDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { TechnicalAreaFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { NONE_VALUE, Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsFieldSingleSelection } from 'components/gql/GroupedOptionsField';
import { TechnicalAreaSelectTarget } from 'components/gql/technicalArea/TechnicalAreaSelectTarget';
import { TechnicalAreaIcon } from 'components/gql/technicalArea/TechnicalAreaWithIcon';
import { createOptimisticStoryMutationResponse } from 'components/gql/utils/mutation';
import { useMutation } from 'gql';
import { useFieldOptionsQuery } from './useFieldOptionsQuery';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const TechnicalAreaFieldFragment = TechnicalAreaFieldFragmentFragmentDoc;
const QUERY_STORY_TECHNICAL_AREA_SELECT = QueryStoryTechnicalAreaSelectDocument;
const MUTATE_STORY_TECHNICAL_AREA_FIELD = MutateStoryTechnicalAreaFieldDocument;
const getSection = (groupKey, options) => ({
  sectionId: groupKey,
  items: options.map(({
    node
  }) => ({
    value: node.id,
    name: node.stringValue,
    Icon: () => _jsx(TechnicalAreaIcon, {
      color: node.colorKey
    })
  }))
});
const TechnicalAreaFieldComponent = ({
  entity: {
    id: storyId,
    technicalArea
  }
}) => {
  const fetchStoryTechnicalAreas = useFieldOptionsQuery({
    query: QUERY_STORY_TECHNICAL_AREA_SELECT,
    storyId,
    includeCurrentPermissionId: false
  });
  const [updateStoryTechnicalArea] = useMutation(MUTATE_STORY_TECHNICAL_AREA_FIELD);
  const handleChange = useCallback(async selectedId => {
    await updateStoryTechnicalArea({
      variables: {
        storyId,
        input: {
          canonicalName: 'technicalArea',
          valueId: selectedId ?? NONE_VALUE
        }
      },
      optimisticResponse: createOptimisticStoryMutationResponse(storyId, {
        technicalArea: selectedId ? {
          __typename: 'CustomFieldEnumValue',
          id: selectedId
        } : null
      })
    });
  }, [storyId, updateStoryTechnicalArea]);
  return _jsx(GroupedOptionsFieldSingleSelection, {
    selectedEntity: technicalArea,
    fetchOptions: fetchStoryTechnicalAreas,
    getSection: getSection,
    TargetComponent: TechnicalAreaSelectTarget,
    onChange: handleChange,
    unit: Nouns.TechnicalArea
  });
};
TechnicalAreaFieldComponent.displayName = "TechnicalAreaFieldComponent";
export const TechnicalAreaField = () => ({
  name: 'technicalArea',
  displayName: Nouns.TechnicalArea.singular,
  sort: true,
  Component: TechnicalAreaFieldComponent,
  width: 170
});