import { useUniqueId } from '@clubhouse/shapes-ds';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const RadioListItem = ({
  active,
  onClick,
  title,
  children
}) => {
  const icon = active ? 'fa-circle' : 'fa-circle-o';
  const id = useUniqueId('radioli');
  return _jsxs("label", {
    className: `radio-list-item ${active ? 'active' : ''}`,
    htmlFor: id,
    children: [_jsx("input", {
      type: "checkbox",
      id: id,
      checked: !!active,
      onChange: onClick
    }), _jsx("span", {
      className: `radio fa ${icon}`
    }), _jsx("span", {
      className: "radio-list-item-title",
      children: title
    }), children]
  });
};
RadioListItem.displayName = "RadioListItem";