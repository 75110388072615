import { EpicTotalPointsFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { TableText } from '@clubhouse/shared/components/Table';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const EpicTotalPointsFieldFragment = EpicTotalPointsFieldFragmentFragmentDoc;
const PointsFieldComponent = ({
  entity
}) => {
  return _jsx(TableText, {
    text: entity.stats.numPointsTotal
  });
};
PointsFieldComponent.displayName = "PointsFieldComponent";
export const TotalPointsField = () => ({
  name: 'numPointsTotal',
  displayName: 'Points',
  headerProps: {
    centered: true
  },
  Component: PointsFieldComponent,
  LoadingComponent: ({
    animate
  }) => _jsx(Center, {
    children: _jsx(SkeletonRectangle, {
      width: 20,
      animate: animate
    })
  }),
  width: 80,
  sort: 'stats_numPoints'
});