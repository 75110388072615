import { DockedEpicFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { getHref } from 'components/gql/epics/links';
import { useCollectionizeEpicContextMenu } from 'utils/contextMenus/useCollectionizeEpicContextMenu';
import { DockedItemTemplate } from './shared/DockedItemTemplate';
import { Title } from './shared/Title';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const DockedEpicFragment = DockedEpicFragmentDoc;
export const isEpic = node => {
  return node?.__typename === 'Epic';
};
export function DockedEpic({
  node,
  index,
  onUnpinClicked
}) {
  const url = getHref(node.publicId);
  const contextMenuProps = useCollectionizeEpicContextMenu(node.publicId);
  return _jsx(DockedItemTemplate, {
    id: node.id,
    index: index,
    onUnpinClicked: onUnpinClicked,
    icon: _jsx(SizedIcon, {
      icon: "Epic",
      fill: "interactive",
      size: 16
    }),
    title: _jsx(Title, {
      url: url,
      children: node.epicName
    }),
    contextMenuProps: contextMenuProps
  });
}
DockedEpic.displayName = "DockedEpic";