import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { useConfirmationDialogState } from '@clubhouse/shared/components/ConfirmationDialog';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { Nouns } from '@clubhouse/shared/constants';
import { useLegacyTasksContext } from '../LegacyTasksContext';
import { useConvertToSubtasks } from '../useConvertToSubtasks';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function ConvertAllToSubtasksAction() {
  const {
    tasks,
    isLoading,
    setIsLoading
  } = useLegacyTasksContext();
  const {
    openDialog,
    closeDialog
  } = useConfirmationDialogState();
  const convertToSubtasks = useConvertToSubtasks({
    setIsLoading
  });
  return _jsx(MoreActions.Item, {
    icon: "Stack",
    isDisabled: isLoading,
    onClick: () => {
      openDialog({
        body: `You are about to convert all Tasks to ${Nouns.Subtask.plural}. This action cannot be undone.`,
        buttonKind: 'primary',
        cta: `Convert all to ${Nouns.Subtask.plural}`,
        onClick: async () => {
          closeDialog();
          setIsLoading(true);
          await convertToSubtasks({
            input: tasks.map(task => ({
              legacyTask: task.global_id
            }))
          });
          setIsLoading(false);
        },
        title: 'Confirm Conversion'
      });
    },
    children: `Convert All to ${Nouns.Subtask.plural}`
  });
}
ConvertAllToSubtasksAction.displayName = "ConvertAllToSubtasksAction";