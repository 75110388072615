import { ArchiveStoryActionFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { Nouns } from '@clubhouse/shared/constants';
import { isLoggedInUserObserver } from 'data/entity/user';
import { useArchiveStoryMutation } from '../hooks/useArchiveStoryMutation';
import { useStoryConfirmationDialog } from '../hooks/useStoryConfirmationDialog';
import { useUnarchiveStoryMutation } from '../hooks/useUnarchiveStoryMutation';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const ArchiveStoryActionFragment = ArchiveStoryActionFragmentDoc;
const showToast = (success, message) => {
  addToast({
    kind: success ? 'success' : 'alert',
    timeout: 5000,
    Content: () => _jsx(ToastText, {
      children: message
    })
  });
};
export const useArchiveStoryDialog = (story, isSubtask = false) => {
  const {
    openDialog,
    closeDialog
  } = useStoryConfirmationDialog();
  const archiveStory = useArchiveStoryMutation();
  const {
    id: storyId,
    name
  } = story;
  const onArchive = useCallback(() => {
    openDialog(isSubtask ? 'archiveSubtask' : 'archive', {
      onClick: async () => {
        try {
          closeDialog();
          await archiveStory(storyId);
          showToast(true, _jsxs(_Fragment, {
            children: [isSubtask ? Nouns.Subtask.singular : Nouns.Story.singular, ' ', _jsx("strong", {
              children: _jsx(Emojify, {
                children: name
              })
            }), ' ', "archived."]
          }));
        } catch {
          showToast(false, _jsxs(_Fragment, {
            children: ["Unable to archive", ' ', _jsx("strong", {
              children: _jsx(Emojify, {
                children: name
              })
            }), "."]
          }));
        }
      }
    });
  }, [archiveStory, isSubtask, closeDialog, openDialog, name, storyId]);
  return onArchive;
};
export const useUnarchiveStoryDialog = (story, isSubtask = false) => {
  const unarchiveStory = useUnarchiveStoryMutation();
  const {
    id: storyId,
    name
  } = story;
  const onUnarchive = useCallback(async () => {
    try {
      await unarchiveStory(storyId);
      showToast(true, _jsxs(_Fragment, {
        children: [isSubtask ? Nouns.Subtask.singular : Nouns.Story.singular, ' ', _jsx("strong", {
          children: _jsx(Emojify, {
            children: name
          })
        }), ' ', "unarchived."]
      }));
    } catch {
      showToast(false, _jsxs(_Fragment, {
        children: ["Unable to unarchive", ' ', _jsx("strong", {
          children: _jsx(Emojify, {
            children: name
          })
        }), "."]
      }));
    }
  }, [storyId, isSubtask, name, unarchiveStory]);
  return onUnarchive;
};
export const ArchiveStoryAction = ({
  story,
  aside,
  isSubtask = false
}) => {
  const onArchive = useArchiveStoryDialog(story, isSubtask);
  const onUnarchive = useUnarchiveStoryDialog(story, isSubtask);
  return _jsxs(MoreActions.Item, {
    icon: "Archive",
    onClick: story.archived ? onUnarchive : onArchive,
    isDisabled: isLoggedInUserObserver(),
    aside: aside,
    children: [story.archived ? 'Unarchive' : 'Archive', " ", isSubtask ? Nouns.Subtask.singular : Nouns.Story.singular]
  });
};
ArchiveStoryAction.displayName = "ArchiveStoryAction";