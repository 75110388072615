import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { useMemo } from 'react';
import { SelectOptionChecked, SelectOptionDivider } from '@clubhouse/shared/components/Select';
import { insertIf } from '@clubhouse/shared/utils';
import { getAllEnabled } from 'data/entity/customField';
import { isProjectsFeatureEnabled } from 'data/entity/feature';
import { usesIterations } from 'utils/tests';
import { GROUP_BY_NONE } from './utils';
export const GROUP_BY = {
  NONE: GROUP_BY_NONE,
  EPIC: 'epic_id',
  ITERATION: 'iteration_id',
  OWNER: 'owner_ids',
  PROJECT: 'project_id',
  STATE: 'workflow_state_id',
  TEAM: 'group_id',
  TYPE: 'story_type'
};
const getStaticGroupByOptions = () => [{
  value: GROUP_BY.EPIC,
  name: 'Epic',
  Component: SelectOptionChecked
}, ...insertIf(usesIterations(), [{
  value: GROUP_BY.ITERATION,
  name: 'Iteration',
  Component: SelectOptionChecked
}]), {
  value: GROUP_BY.OWNER,
  name: 'Owner',
  Component: SelectOptionChecked
}, ...insertIf(isProjectsFeatureEnabled(), [{
  value: GROUP_BY.PROJECT,
  name: 'Project',
  Component: SelectOptionChecked
}]), {
  value: GROUP_BY.STATE,
  name: 'State',
  Component: SelectOptionChecked
}, {
  value: GROUP_BY.TEAM,
  name: 'Team',
  Component: SelectOptionChecked
}, {
  value: GROUP_BY.TYPE,
  name: 'Type',
  Component: SelectOptionChecked
}];
export const getGroupByOptions = (exclude, excludeFields) => {
  const fields = getAllEnabled();
  const values = [...getStaticGroupByOptions(), ...insertIf(!excludeFields, fields.map(field => ({
    value: String(field.id),
    name: field.name,
    Component: SelectOptionChecked
  })))].filter(item => !exclude || !exclude.includes(item.value)).sort((a, b) => a.name.localeCompare(b.name));
  return [{
    value: GROUP_BY.NONE,
    name: 'None'
  }, {
    Component: SelectOptionDivider,
    key: 'divider'
  }, ...values];
};
export const useGroupByOptions = (exclude, excludeFields) => {
  return useMemo(() => getGroupByOptions(exclude, excludeFields), [exclude, excludeFields]);
};