import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { UpdateEpicObjectivesDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { EpicObjectiveSelectDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { EpicTableRowObjectivesFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback, useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { GroupedOptionsSelect, useFetchGroupedOptions } from '@clubhouse/shared/components/GroupedOptionsSelect';
import { Divider, Nothing } from '@clubhouse/shared/components/Select';
import { Nouns } from '@clubhouse/shared/constants';
import { useMutation } from 'gql';
import { ObjectivesSelectTarget } from '../../../objective/ObjectivesSelectTarget';
import { getSection } from '../../../objective/utils/sections';
import { createOptimisticEpicMutationResponse } from '../../../utils/mutation';
import { useFieldOptionsQuery } from './useFieldOptionsQuery';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const EpicTableRowObjectivesFragment = EpicTableRowObjectivesFragmentDoc;
const EpicObjectivesSelectQuery = EpicObjectiveSelectDocument;
const UpdateEpicObjectives = UpdateEpicObjectivesDocument;
const StaticItems = [Nothing(), Divider('after-none')];
const ObjectivesFieldComponent = ({
  entity: {
    id,
    objectives
  }
}) => {
  const fetchObjectives = useFieldOptionsQuery({
    query: EpicObjectivesSelectQuery,
    epicId: id,
    includeCurrentPermissionId: false
  });
  const [save] = useMutation(UpdateEpicObjectives);
  const handleChange = useCallback((objectiveIds, objectives) => {
    save({
      variables: {
        id,
        input: {
          objectives: {
            reset: objectiveIds
          }
        }
      },
      optimisticResponse: createOptimisticEpicMutationResponse(id, {
        objectives: {
          __typename: 'ObjectiveConnection',
          edges: objectives.map(({
            additionalData: objective
          }) => ({
            __typename: 'ObjectiveEdge',
            node: {
              __typename: 'Objective',
              id: objective.id,
              name: objective.name,
              state: objective.state
            }
          })),
          pageInfo: {
            __typename: 'PageInfo',
            totalSize: objectiveIds.length
          }
        }
      })
    });
  }, [id, save]);
  const selectedValues = useMemo(() => (objectives?.edges || []).map(e => e.node.id), [objectives?.edges]);
  const {
    fetchSections,
    items,
    totalItemCount
  } = useFetchGroupedOptions({
    fetchOptions: fetchObjectives,
    selectedValues,
    getSection,
    staticItems: StaticItems
  });
  return _jsx(GroupedOptionsSelect, {
    items: items,
    totalItemCount: totalItemCount,
    fetchSections: fetchSections,
    selectedValues: selectedValues,
    onChange: handleChange,
    TargetComponent: ObjectivesSelectTarget,
    targetComponentProps: useMemo(() => ({
      selectedEntities: (objectives?.edges || []).map(e => e.node),
      totalCount: objectives?.pageInfo.totalSize ?? 0
    }), [objectives?.edges, objectives?.pageInfo.totalSize]),
    unit: Nouns.Objective,
    label: `Update ${Nouns.Objective.singular}`
  });
};
ObjectivesFieldComponent.displayName = "ObjectivesFieldComponent";
export const ObjectivesField = () => ({
  name: 'objectives',
  displayName: 'Objectives',
  sort: 'objective_name',
  Component: ObjectivesFieldComponent,
  width: 240
});