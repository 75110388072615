import { DockedStoryFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useTheme } from '@emotion/react';
import { gql } from '@clubhouse/datalayer';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { getHref } from 'components/gql/stories/links';
import { useCollectionizeStoryContextMenu } from 'utils/contextMenus/useCollectionizeStoryContextMenu';
import { DockedItemTemplate } from './shared/DockedItemTemplate';
import { Title } from './shared/Title';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const DockedStoryFragment = DockedStoryFragmentDoc;
const storyTypeToIconConfig = {
  bug: {
    icon: 'Bug',
    getColor: theme => theme.color.error.main
  },
  feature: {
    icon: 'Feature',
    getColor: theme => theme.color.warning.dark
  },
  chore: {
    icon: 'Chore',
    getColor: theme => theme.color.secondary.dark
  }
};
export const isStory = node => {
  return node?.__typename === 'Story';
};
export function DockedStory({
  node,
  index,
  onUnpinClicked
}) {
  const url = getHref(node.publicId, node.name);
  const theme = useTheme();
  const iconConfig = storyTypeToIconConfig[node.type];
  const cxtMenuProps = useCollectionizeStoryContextMenu(node.publicId);
  return _jsx(DockedItemTemplate, {
    contextMenuProps: cxtMenuProps,
    id: node.id,
    index: index,
    onUnpinClicked: onUnpinClicked,
    icon: _jsx(SizedIcon, {
      icon: iconConfig.icon,
      customFill: iconConfig.getColor(theme),
      size: 16
    }),
    title: _jsx(Title, {
      url: url,
      children: node.name
    })
  });
}
DockedStory.displayName = "DockedStory";