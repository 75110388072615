import { Icon } from '@clubhouse/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { DetailPageSidebarRow, IconContainer } from './DetailPageSidebarRow';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function DetailPageSidebarStatsRow({
  label,
  icon,
  value,
  children
}) {
  return _jsxs(DetailPageSidebarRow, {
    label: label,
    align: "center",
    children: [_jsx(IconContainer, {
      style: {
        marginRight: 5
      },
      children: _jsx(DeprecatedIconAdapter, {
        width: 16,
        children: typeof icon === 'string' ? _jsx(Icon, {
          icon: icon
        }) : icon
      })
    }), _jsx("span", {
      children: value
    }), children]
  });
}
DetailPageSidebarStatsRow.displayName = "DetailPageSidebarStatsRow";