import { Button } from '@clubhouse/shared/components/Button';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { ConvertToStoryAction } from './ConvertToStoryAction';
import { ConvertToSubtaskAction } from './ConvertToSubtaskAction';
import { DeleteTaskAction } from './DeleteTaskAction';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function LegacyTaskActionMenu({
  task,
  setIsLoading,
  isLoading
}) {
  return _jsxs(MoreActions, {
    children: [_jsx(MoreActions.Trigger, {
      asChild: true,
      children: _jsx(Button, {
        kind: "ghost",
        fit: "exact",
        size: "small",
        children: _jsx(SizedIcon, {
          size: 18,
          icon: "More",
          label: "Open actions menu"
        })
      })
    }), _jsxs(MoreActions.Menu, {
      children: [_jsx(ConvertToSubtaskAction, {
        taskGlobalId: task.global_id,
        setIsLoading: setIsLoading,
        isLoading: isLoading
      }), _jsx(ConvertToStoryAction, {
        task: task,
        setIsLoading: setIsLoading,
        isLoading: isLoading
      }), _jsx(MoreActions.Divider, {}), _jsx(DeleteTaskAction, {
        task: task,
        setIsLoading: setIsLoading,
        isLoading: isLoading
      })]
    })]
  });
}
LegacyTaskActionMenu.displayName = "LegacyTaskActionMenu";