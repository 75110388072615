import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import { QueryWorkspaceObjectiveSelectDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useEffect } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsFilter, useFetchGroupedOptions } from 'components/gql/filters/GroupedOptionsFilter';
import { useFilterOptionsQuery } from 'components/gql/filters/hooks/useFilterOptionsQuery';
import { useFilterState } from 'components/gql/filters/hooks/useFilterState';
import { EVENTS, logEvent } from 'utils/monitoring';
import { getSection } from './utils/sections';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const WorkspaceObjectiveSelectQuery = QueryWorkspaceObjectiveSelectDocument;
export function ObjectivesFilter({
  entityType
}) {
  // TODO: Remove when we feel like milestones have fallen enough by the wayside and bookmarks aren't saved
  const [selectedMilestones, setSelectedMilestones] = useFilterState({
    paramName: 'milestone_ids',
    paramType: 'string[]'
  });
  const [selectedObjectives, setSelectedObjectives] = useFilterState({
    paramName: 'objective_ids',
    paramType: 'string[]'
  });

  /*
   * This needs to stay even after the feature toggle is removed.  This will be to avoid breaking existing links
   * as much as possible.  The events are here to help us track how essential this is in the future.
   *
   * Ideally, this event will be triggered less and less over time and can then be removed.
   *
   */
  useEffect(() => {
    if (selectedMilestones.length > 0) {
      logEvent(EVENTS.Roadmap_Milestones_And_Objectives_Conversion);
      setSelectedObjectives([...new Set([...selectedObjectives, ...selectedMilestones])]);
      setSelectedMilestones([]);
    }
  }, [selectedMilestones, selectedObjectives, setSelectedObjectives, setSelectedMilestones]);
  const fetchObjectives = useFilterOptionsQuery({
    query: WorkspaceObjectiveSelectQuery,
    selectedIds: selectedObjectives
  });
  const {
    fetchSections,
    items,
    totalItemCount
  } = useFetchGroupedOptions({
    fetchOptions: fetchObjectives,
    getSection,
    selectedValues: selectedObjectives,
    filterType: Nouns.Objective
  });
  return _jsx(GroupedOptionsFilter, {
    fetchSections: fetchSections,
    items: items,
    totalItemCount: totalItemCount,
    entityType: entityType,
    selectedValues: selectedObjectives,
    onChange: setSelectedObjectives,
    filterType: Nouns.Objective,
    icon: "Objectives"
  });
}
ObjectivesFilter.displayName = "ObjectivesFilter";