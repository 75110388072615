import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import { all, isActiveGroup, sortGroupByName } from 'data/entity/group';
import { sortByCategory } from './utils/sort';
const myTeamsCategory = 'My Teams';
const otherTeamsCategory = 'Other Teams';
const getTeamCategory = (currentMemberId, team) => {
  return currentMemberId && team?.member_ids?.includes(currentMemberId) ? myTeamsCategory : otherTeamsCategory;
};
export const formatTeam = currentMemberId => team => ({
  ...team,
  category: getTeamCategory(currentMemberId, team)
});
export const formatTeams = currentMemberId => {
  return all().filter(isActiveGroup).sort(sortGroupByName).map(formatTeam(currentMemberId)).sort(sortByCategory);
};