import { EpicIconEpicFragmentDoc } from "../../../datalayer/__generated_graphql_types__/graphql";
import { StatusIcon } from '@clubhouse/shapes-ds';
import { gql } from '@clubhouse/datalayer';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const EpicIconEpicFragment = EpicIconEpicFragmentDoc;
export const EpicIcon = ({
  epic,
  width = '15px'
}) => {
  const {
    type
  } = epic.stateObject;
  if (type === 'started') {
    return _jsx(DeprecatedIconAdapter, {
      width: width,
      children: _jsx(StatusIcon, {
        icon: "Started"
      })
    });
  } else if (type === 'done') {
    return _jsx(DeprecatedIconAdapter, {
      width: width,
      children: _jsx(StatusIcon, {
        icon: "Done"
      })
    });
  } else {
    return _jsx(DeprecatedIconAdapter, {
      width: width,
      children: _jsx(StatusIcon, {
        icon: "Unstarted"
      })
    });
  }
};