import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { getFromOrg } from 'data/entity/company';
import { getOrgProfileForLoggedInUser } from 'data/entity/organization';
import { getLoggedInUserPermission } from 'data/entity/user';
export const getWorkspaceCategory = workspace => {
  const profile = getOrgProfileForLoggedInUser(workspace.id);
  const permission = getLoggedInUserPermission(workspace.id);
  const isDefault = profile.default && permission.default;
  if (isDefault) {
    return 'Default';
  }
  const company = getFromOrg(workspace);
  return company ? company.name : '';
};
export const formatWorkspace = workspace => {
  const {
    id,
    name,
    url_slug
  } = workspace;
  const category = getWorkspaceCategory(workspace);
  return {
    id,
    name,
    url_slug,
    category
  };
};
export const formatWorkspaces = workspaces => workspaces.map(formatWorkspace);