import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { DetachFromParentActionStoryFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback, useEffect, useState } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Button } from '@clubhouse/shared/components/Button';
import { useConfirmationDialogState } from '@clubhouse/shared/components/ConfirmationDialog';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { Nouns } from '@clubhouse/shared/constants';
import { copyToClipboard } from '@clubhouse/shared/utils/copyToClipboard';
import { useUpdateParentStoryMutation } from 'components/gql/stories/hooks/useUpdateParentStoryMutation';
import { StoryAutoLink } from 'components/shared/StoryAutoLink';
import { useWorkspaceSlug } from 'utils/navigation';
import { getHref } from 'utils/navigation/story';
import { openOrFetchAndOpen } from 'utils/storyDialog';
import { useSubtaskLoadingContext } from '../SubtaskLoadingContext';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const DetachFromParentActionStoryFragment = DetachFromParentActionStoryFragmentDoc;
const ToastContent = ({
  subtask,
  closeDialog
}) => {
  const slug = useWorkspaceSlug();
  const [copied, setCopied] = useState(false);
  const storyUrl = new URL(getHref(slug, {
    publicId: subtask.publicId,
    name: subtask.name
  }), window.location.origin);
  useEffect(() => {
    let timeout;
    if (copied) {
      timeout = setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
    return function cleanup() {
      clearTimeout(timeout);
    };
  }, [copied]);
  const onCopyClick = async () => {
    await copyToClipboard(storyUrl.href);
    setCopied(true);
  };
  return _jsxs(Spaced, {
    vertically: true,
    amount: 8,
    children: [_jsxs(ToastText, {
      children: [Nouns.Subtask.singular, ' ', _jsx("strong", {
        children: _jsx(StoryAutoLink, {
          id: subtask.id,
          publicId: subtask.publicId,
          name: subtask.name,
          type: subtask.type
        })
      }), ' ', "was detached."]
    }), _jsxs(Spaced, {
      horizontally: true,
      amount: 5,
      children: [_jsx(Button, {
        kind: Button.KIND.SECONDARY,
        size: Button.SIZE.MEDIUM,
        fit: Button.FIT.MEDIUM,
        IconLeft: () => _jsx(SizedIcon, {
          icon: "View",
          size: 14
        }),
        onClick: () => {
          openOrFetchAndOpen(subtask.publicId);
          closeDialog();
        },
        children: "View"
      }), _jsx(Tooltip, {
        content: copied ? 'Copied!' : 'Copy to Clipboard',
        children: _jsx(Button, {
          kind: Button.KIND.SECONDARY,
          size: Button.SIZE.MEDIUM,
          fit: Button.FIT.MEDIUM,
          IconLeft: () => _jsx(SizedIcon, {
            icon: "Copy",
            size: 14
          }),
          onClick: onCopyClick,
          children: "Copy Link"
        })
      })]
    })]
  });
};
ToastContent.displayName = "ToastContent";
export function DetachFromParentAction({
  subtask
}) {
  const updateParentStory = useUpdateParentStoryMutation();
  const {
    openDialog,
    closeDialog
  } = useConfirmationDialogState();
  const {
    handleSubtaskLoading
  } = useSubtaskLoadingContext();
  const onDetachFromParent = useCallback(() => {
    openDialog({
      body: `This ${Nouns.Subtask.singular} will be detached from the parent and converted to a ${Nouns.Story.singular}.`,
      buttonKind: Button.KIND.PRIMARY,
      cta: 'Detach from Parent',
      onClick: async () => {
        handleSubtaskLoading(true, subtask.id);
        try {
          await updateParentStory({
            storyId: subtask.id,
            parentStory: null
          });
          closeDialog();
          addToast({
            kind: 'success',
            timeout: 5000,
            Content: () => _jsx(ToastContent, {
              subtask: subtask,
              closeDialog: closeDialog
            }),
            width: 375
          });
        } catch (error) {
          handleSubtaskLoading(false, subtask.id);
        }
      },
      title: 'Confirm Detach from Parent'
    });
  }, [closeDialog, openDialog, subtask, updateParentStory, handleSubtaskLoading]);
  return _jsx(MoreActions.Item, {
    icon: "Story",
    onClick: onDetachFromParent,
    children: "Detach from parent"
  });
}
DetachFromParentAction.displayName = "DetachFromParentAction";