import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import * as LabelData from 'data/entity/label';
import { isNew } from 'data/entity/story';
import * as AddNew from 'utils/addNew';
import { saveLabelsToStory } from 'utils/story';
import { getCommandBar } from '../../getCommandBar';
import { isUserReadOnly } from '../../isUserReadOnly';
import { lazilyLoadArgumentChoices } from '../../lazilyLoadArgumentChoices';
export const addContextForStoryLabels = story => {
  lazilyLoadArgumentChoices('currentLabelsForStory', () => getCurrentLabelsForStory(story));
  lazilyLoadArgumentChoices('labelsForCurrentStory', () => getLabelsForCurrentStory(story));
  if (!isUserReadOnly()) {
    getCommandBar().addCallback('updateStoryLabels', updateStoryLabels(story));
  }
};
const getCurrentLabelsForStory = story => {
  if (!story) return [];
  return story.labels;
};
const getLabelsForCurrentStory = story => {
  if (!story) return [];
  return LabelData.getItemsForLabelsAutocomplete(story).filter(label => label.value).map(label => label.value);
};
const updateStoryLabels = story => ({
  labels
}) => {
  const existingLabels = labels.filter(label => !label._createdByCommandBar);
  const newLabel = labels.find(label => label._createdByCommandBar);
  const newLabels = newLabel ? LabelData.addLabelAsString(existingLabels, `"${newLabel.value}"`) : existingLabels;
  if (isNew(story)) {
    AddNew.updateState({
      labels: newLabels
    });
  } else {
    saveLabelsToStory(story, newLabels);
  }
};