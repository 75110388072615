import { makeVar, useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';
const temporarySubtaskVar = {
  id: '',
  publicId: 0,
  name: '',
  workflowState: {
    id: '',
    type: 'started',
    name: ''
  }
};
const subTaskVar = makeVar(temporarySubtaskVar);
export const useSubtaskLoadingState = () => {
  const tempSubtask = useReactiveVar(subTaskVar);
  const setTempSubtask = useCallback(subtask => {
    const newSubtask = {
      ...temporarySubtaskVar,
      ...subtask,
      workflowState: {
        ...temporarySubtaskVar.workflowState,
        ...subtask.workflowState
      }
    };
    subTaskVar(newSubtask);
  }, []);
  const resetTempSubtask = () => subTaskVar(temporarySubtaskVar);
  return {
    tempSubtask,
    setTempSubtask,
    resetTempSubtask
  };
};