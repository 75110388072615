import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { deleteTask } from 'data/entity/task';
import { logError } from 'utils/monitoring';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function DeleteTaskAction({
  task,
  setIsLoading,
  isLoading
}) {
  return _jsx(MoreActions.Item, {
    icon: "Trash",
    isDisabled: isLoading,
    onClick: async () => {
      setIsLoading(true);
      try {
        await deleteTask(task);
      } catch (err) {
        setIsLoading(false);
        logError(new Error('Unable to delete the task', {
          cause: err
        }));
        addToast({
          kind: 'alert',
          width: 400,
          timeout: 5000,
          Content: () => _jsx(ToastText, {
            children: "Unable to delete the task"
          })
        });
      }

      // We don't call `setIsLoading(false)` if it's a success, because we want to keep
      // the loading state until the row disappears.
    },
    children: "Delete Task"
  });
}
DeleteTaskAction.displayName = "DeleteTaskAction";