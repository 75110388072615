import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { Loading } from '@clubhouse/shared/components/Loading/Loading';
import { TIERS } from '@clubhouse/shared/types';
import { pluralize } from '@clubhouse/shared/utils/pluralize';
import { getOrganizationOwners, useOrganization } from 'data/entity/organization';
import { getAvailableSeats, planIsTier, usePaymentPlanForOrganization, usePlanStatuses } from 'data/entity/paymentPlan2';
import { isLoggedInUserOrganizationOwner } from 'data/entity/user';
import { grammaticalJoin, toMoney } from 'utils/format';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const OutstandingBalanceReason = ({
  balance
}) => balance ? _jsxs("li", {
  children: [_jsx("span", {
    className: "fa fa-usd"
  }), "You have an outstanding balance of ", _jsxs("strong", {
    children: ["$", toMoney(balance / 100)]
  }), ". This will be charged when your account is re-enabled."]
}) : null;
const TrialingReason = ({
  plan
}) => {
  const {
    planTrialInfo
  } = usePlanStatuses(plan);
  if (!planTrialInfo) return null;
  const dateText = planTrialInfo.trial_days_remaining === 0 ? _jsx("strong", {
    children: "today"
  }) : _jsxs(_Fragment, {
    children: [_jsx("strong", {
      children: planTrialInfo.trial_end
    }), " (in", ' ', pluralize({
      count: planTrialInfo.trial_days_remaining,
      singular: 'day',
      plural: 'days'
    }), ")"]
  });
  return _jsxs("li", {
    children: ["You still have an active trial, which will end ", dateText, "."]
  });
};
TrialingReason.displayName = "TrialingReason";
const SeatEntitlementReason = ({
  plan
}) => {
  const {
    planTrialInfo
  } = usePlanStatuses(plan);

  // Do not show seat information for disabled trials
  if (planTrialInfo) return null;
  const isFreePlan = planIsTier(plan, TIERS.FREE_LIMITED);
  const hasActiveSeatsLeft = getAvailableSeats(plan);
  const freePlanSeatLimit = 10;
  const freePlanEligibleMessage = `You are eligible for the Free Plan for up to ${freePlanSeatLimit} Users.`;
  const standardPlanForcedMessage = `Your account will be reactivated on the Team Plan if you have more than ${freePlanSeatLimit} Users.`;
  let message = null;
  if (isFreePlan) {
    message = hasActiveSeatsLeft ? freePlanEligibleMessage : standardPlanForcedMessage;
  }
  if (!isFreePlan) {
    message = hasActiveSeatsLeft ? freePlanEligibleMessage : standardPlanForcedMessage;
  }
  return _jsx("li", {
    children: message
  });
};
SeatEntitlementReason.displayName = "SeatEntitlementReason";
export const DisabledOrgReasons = ({
  orgId
}) => {
  const {
    org
  } = useOrganization(orgId);
  const paymentPlan = usePaymentPlanForOrganization(orgId);
  const ownerNames = getOrganizationOwners(org).map(({
    name
  }) => name);

  // This check must come first, the payment plan might not be loaded. The plan is only loaded for users that are owners of the org.
  if (!isLoggedInUserOrganizationOwner(org)) return _jsxs("li", {
    children: ["This organization has been disabled and can only be re-enabled by an owner", ownerNames.length ? `, ${grammaticalJoin(ownerNames)}` : '', "."]
  });
  if (!paymentPlan) return _jsx("li", {
    children: _jsx(Loading, {})
  });
  return _jsxs(_Fragment, {
    children: [_jsx(OutstandingBalanceReason, {
      balance: paymentPlan.account_balance
    }), _jsx(TrialingReason, {
      plan: paymentPlan
    }), _jsx(SeatEntitlementReason, {
      plan: paymentPlan
    })]
  });
};