import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { useCallback, useState } from 'react';
import { SelectField } from '@clubhouse/shared/components/Select';
import { SelectOptionChecked } from '@clubhouse/shared/components/Select/SelectOption';
import { WORKING_DAYS } from '@clubhouse/shared/constants';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ITEMS = Object.keys(WORKING_DAYS).map(key => ({
  name: key,
  value: `${WORKING_DAYS[key]}`,
  Component: SelectOptionChecked
}));
export const WorkingDaysSelect = ({
  workingDays,
  onChange
}) => {
  const [selectedWorkingDays, setSelectedWorkingDays] = useState(() => workingDays.sort().map(day => `${day}`));
  const handleChange = useCallback(newWorkingDays => {
    const sortedWorkingDays = newWorkingDays.sort();
    setSelectedWorkingDays(sortedWorkingDays);
    onChange(sortedWorkingDays.map(day => Number(day)));
  }, [onChange]);
  return _jsx(SelectField, {
    items: ITEMS,
    maxSelected: ITEMS.length,
    selectedValues: selectedWorkingDays,
    label: "Set Working Days",
    placeholder: "Select at least one Working Day.",
    hasError: selectedWorkingDays.length === 0,
    onChange: handleChange
  });
};
WorkingDaysSelect.displayName = "WorkingDaysSelect";