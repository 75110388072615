import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { getEmojiCodeFromUnicode } from '@clubhouse/shared/utils/emoji';
import { isParseableUrl } from '@clubhouse/shared/utils/url';
// Google Docs wraps the entire content in a <b> tag with style="font-weight: normal".
// To avoid wrapping the full doc in **bold** we have a special case here.
const isGoogleDocRoot = node => {
  return node.nodeName.toLowerCase() === 'b' && /^docs-internal-guid/i.test(node.getAttribute('id') || '');
};
const header = n => ({
  fn: (children, {
    nodeHierarchy
  }) => nodeHierarchy.includes('a') ? children : `\n\n${'#'.repeat(n)} ${children}\n\n`
});
const moveSpaces = (str, symbol) => {
  let spaceBefore = 0,
    spaceAfter = 0;
  for (let i = 0; i < Math.floor(str.length / 2); ++i) {
    const lastCharIsSpace = str[str.length - i - 1] === ' ';
    const firstCharIsSpace = str[i] === ' ';
    if (!firstCharIsSpace && !lastCharIsSpace) break;
    if (firstCharIsSpace) spaceBefore++;
    if (lastCharIsSpace) spaceAfter++;
  }
  return `${' '.repeat(spaceBefore)}${symbol}${str.trim()}${symbol}${' '.repeat(spaceAfter)}`;
};
const SUPPORTED_NODE_TYPES = {
  h1: header(1),
  h2: header(2),
  h3: header(3),
  h4: header(4),
  h5: header(5),
  h6: header(6),
  p: {
    fn: (children, {
      nodeHierarchy
    }) => `${nodeHierarchy.length === 0 ? '\n\n' : ''}${children}${nodeHierarchy.length === 0 ? '\n\n' : ''}`
  },
  strong: {
    fn: children => moveSpaces(children, '**')
  },
  b: {
    fn: (children, {
      node
    }) => {
      if (isGoogleDocRoot(node)) return children;
      return moveSpaces(children, '**');
    }
  },
  em: {
    fn: children => moveSpaces(children, '_')
  },
  i: {
    fn: children => moveSpaces(children, '_')
  },
  u: {
    fn: children => moveSpaces(children, '__')
  },
  del: {
    fn: children => moveSpaces(children, '~~')
  },
  code: {
    fn: (children, {
      nodeHierarchy
    }) => nodeHierarchy.includes('pre') ? children : moveSpaces(children, '`')
  },
  pre: {
    fn: children => {
      if (children.includes('\n')) return `\n\n\`\`\`\n${children}\n\`\`\`\n\n`;else return moveSpaces(children, '`');
    }
  },
  a: {
    fn: (children, {
      node
    }) => {
      const href = node.getAttribute('href');
      if (children.trim() === href && /^https?:\/\//.test(href)) return href;
      return `[${children}](${href})`;
    }
  },
  blockquote: {
    fn: (children, {
      nodeHierarchy
    }) => {
      const indentation = nodeHierarchy.filter(name => name === 'blockquote').length;
      const isNested = indentation > 0;
      return `${isNested ? '\n' : '\n\n'}${'>'.repeat(indentation)}> ${children}${isNested ? '\n' : '\n\n'}`;
    }
  },
  ul: {
    fn: (children, {
      nodeHierarchy
    }) => {
      const inTable = nodeHierarchy.includes('table');
      if (inTable) return `<ul>${children}</ul>`;
      const isNested = nodeHierarchy.includes('li');
      return `${isNested ? '\n' : '\n\n'}${children}${isNested ? '' : '\n\n'}`;
    }
  },
  ol: {
    fn: (children, {
      nodeHierarchy
    }) => {
      const inTable = nodeHierarchy.includes('table');
      if (inTable) return `<ol>${children}</ol>`;
      const isNested = nodeHierarchy.includes('li');
      return `${isNested ? '\n' : '\n\n'}${children}${isNested ? '' : '\n\n'}`;
    }
  },
  li: {
    fn: (children, {
      node,
      nodeHierarchy
    }) => {
      const inTable = nodeHierarchy.includes('table');
      if (inTable) return `<li>${children}</li>`;
      const isOrdered = nodeHierarchy.includes('ol');
      let index = 0;
      let n = node;
      while (n = n.previousSibling) index++;
      return `${' '.repeat(nodeHierarchy.filter(name => name === 'li').length * 4)}${isOrdered ? `${index + 1}. ` : '- '}${children}${children.endsWith('\n') ? '' : '\n'}`;
    }
  },
  table: {
    fn: (children, {
      node
    }) => {
      if (node.querySelector('thead')) return children;
      const cols = node.querySelector('tr')?.childElementCount || 0;
      if (!cols) return children;
      return `|${Array.from({
        length: cols
      }, () => ' ').join('|')}|\n|${Array.from({
        length: cols
      }, () => ' --- ').join('|')}|\n${children}`;
    }
  },
  thead: {
    fn: (children, {
      node
    }) => {
      const cols = node.querySelector('tr')?.childElementCount || 0;
      if (!cols) return children;
      return `${children}|${Array.from({
        length: cols
      }, () => ' --- ').join('|')}|\n`;
    }
  },
  tr: {
    fn: children => `|${children}\n`
  },
  th: {
    fn: children => ` ${children.replace(/\n/g, '<br>')} |`
  },
  td: {
    fn: children => ` ${children.replace(/\n/g, '<br>')} |`
  },
  hr: {
    fn: () => '\n\n---\n\n'
  },
  br: {
    fn: (_, {
      nodeHierarchy
    }) => nodeHierarchy.includes('tr') ? '<br>' : '\n'
  },
  img: {
    fn: (_, {
      node
    }) => {
      const alt = node.getAttribute('alt') || '';
      const src = node.getAttribute('src') || '';
      if (isParseableUrl(src)) {
        const url = new URL(src);
        const filenameWithExt = url.pathname.split('/').at(-1) || '';
        const filename = filenameWithExt.split('.').slice(0, -1).join('.');
        const emojiCode = getEmojiCodeFromUnicode(filename);
        if (emojiCode) return emojiCode;
      }
      return `![${alt}](${src})\n`;
    }
  },
  video: {
    fn: (_, {
      node
    }) => {
      const source = node.querySelector('source[src]')?.getAttribute('src');
      if (!source) return '';
      return `[](${source})\n`;
    }
  }
};
const isHtmlComment = str => /^<!--.*-->$/.test(str);
const isEmptyTextContent = str => /^\s*$/.test(str);
const getFormattedTextFromNode = (n, nodeHierarchy, parentMapper, context) => {
  if (!n) return '';
  let str = '';
  if (n.nodeType === document.ELEMENT_NODE) {
    const nodeName = n.nodeName.toLowerCase();
    const mapper = SUPPORTED_NODE_TYPES[nodeName];
    if (n.hasChildNodes()) str = getFormattedTextFromNode(n.firstChild, [...nodeHierarchy, nodeName], mapper, context);
    if (mapper) {
      context.total += 1;
      context.byNodeType[nodeName] = (context.byNodeType[nodeName] || 0) + 1;
      str = mapper.fn(str, {
        nodeHierarchy,
        node: n
      });
    }
  } else if (n.nodeType === document.TEXT_NODE) {
    const content = n.textContent || '';
    if (isHtmlComment(content)) str = '';else str = content;
  }
  str += getFormattedTextFromNode(n.nextSibling, nodeHierarchy, parentMapper, context);
  return str;
};
const findRoot = body => {
  if (!body) return null;
  let child = body.firstChild;
  let el = body.firstElementChild;
  if (child?.nodeType === document.TEXT_NODE && !isHtmlComment(child.textContent || '') && !isEmptyTextContent(child.textContent || '')) {
    return body;
  }
  while (el && isGoogleDocRoot(el)) el = el?.firstElementChild ?? el.nextElementSibling;
  return el;
};
export function parseHtmlToMarkdown(html) {
  if (!html) return null;
  try {
    const root = findRoot(new DOMParser().parseFromString(html, 'text/html').body);
    const nodeHierarchy = [];
    const context = {
      total: 0,
      byNodeType: {}
    };
    const str = getFormattedTextFromNode(root, nodeHierarchy, null, context);
    if (context.total === 0 || context.total === context.byNodeType.br) return null;
    return str.replace(/(?:^\n+)|(?:\n+$)/g, '').replace(/\n{3,}/g, '\n\n');
  } catch {}
  return null;
}