import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import { PRIORITY_ICON_COLORS, SEVERITY_ICON_COLORS } from '@clubhouse/shapes-ds';
import { getTint } from '@clubhouse/shared/color';
import { OpinionatedFieldType } from '@clubhouse/shared/types';
import { getById as getCustomFieldById } from 'data/entity/customField';
import { getById as getGroupById, getGroupColor } from 'data/entity/group';
import { getById as getProjectById } from 'data/entity/project';
const STORY_TYPE_COLORS = {
  feature: 'var(--storyCardFeatureIdColor)',
  bug: 'var(--storyCardBugIdColor)',
  chore: 'var(--storyCardChoreIdColor)'
};
const getStoryGroupColor = story => {
  if (!story.group_id) {
    return 'transparent';
  }
  const group = getGroupById(story.group_id);
  return group ? getGroupColor(group) : 'transparent';
};
const getStoryProjectColor = story => {
  if (!story.project_id) {
    return 'transparent';
  }
  const project = getProjectById(story.project_id);
  return project?.color ?? 'transparent';
};
const getStoryCustomFieldValueColor = (storyFields, name) => {
  const DEFAULT_COLOR = 'transparent';
  for (const {
    field_id,
    value_id
  } of storyFields) {
    const field = getCustomFieldById(field_id);
    if (field && field.name === name) {
      const value = field.values.find(({
        id
      }) => value_id === id);
      if (!value) {
        return DEFAULT_COLOR;
      }
      if (name.toLowerCase() === OpinionatedFieldType.SEVERITY) {
        return Object.values(SEVERITY_ICON_COLORS)[value.position];
      }
      if (name.toLowerCase() === OpinionatedFieldType.PRIORITY) {
        return Object.values(PRIORITY_ICON_COLORS)[value.position];
      }
      if (value.color_key) {
        return getTint(value.color_key);
      }
    }
  }
  return DEFAULT_COLOR;
};
export const getCardColor = (story, colorOption) => {
  switch (colorOption) {
    case 'project':
      return getStoryProjectColor(story);
    case 'team':
      return getStoryGroupColor(story);
    case 'story-type':
      return STORY_TYPE_COLORS[story.story_type];
    default:
      return getStoryCustomFieldValueColor(story.custom_fields, colorOption);
  }
};