import noop from 'lodash/noop';
import { useCallback } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { useConfirmationDialogState } from '@clubhouse/shared/components/ConfirmationDialog';
import { Nouns } from '@clubhouse/shared/constants';
const DIALOGS = {
  archive: {
    body: `Archiving this ${Nouns.Story.singular} will exclude it from all reports and charts, as well as from the ${Nouns.Story.plural} page.`,
    buttonKind: Button.KIND.WARNING,
    cta: `Archive ${Nouns.Story.singular}`,
    onClick: noop,
    title: `Are you sure you want to archive this ${Nouns.Story.singular}?`
  },
  archiveSubtask: {
    body: `Archiving this ${Nouns.Subtask.singular} will exclude it from all reports and charts, as well as from the ${Nouns.Story.plural} page.`,
    buttonKind: Button.KIND.WARNING,
    cta: `Archive ${Nouns.Subtask.singular}`,
    onClick: noop,
    title: `Are you sure you want to archive this ${Nouns.Subtask.singular}?`
  },
  delete: {
    body: `Deleting this ${Nouns.Story.singular} will permanently remove it from ${BRAND.NAME}, and you will not be able to recover it.`,
    buttonKind: Button.KIND.WARNING,
    cta: `Delete ${Nouns.Story.singular}`,
    onClick: noop,
    title: `Are you sure you want to delete this ${Nouns.Story.singular}?`
  },
  deleteSubtask: {
    body: `Deleting this ${Nouns.Subtask.singular} will permanently remove it from ${BRAND.NAME}, and you will not be able to recover it.`,
    buttonKind: Button.KIND.WARNING,
    cta: `Delete ${Nouns.Subtask.singular}`,
    onClick: noop,
    title: `Are you sure you want to delete this ${Nouns.Subtask.singular}?`
  },
  detachFromParent: {
    body: `This ${Nouns.Subtask.singular} will be detached from the parent and converted to a ${Nouns.Story.singular}.`,
    onClick: noop,
    buttonKind: Button.KIND.PRIMARY,
    cta: 'Detach from Parent',
    title: 'Confirm Detach from Parent'
  }
};
export function useStoryConfirmationDialog() {
  const {
    openDialog: open,
    closeDialog
  } = useConfirmationDialogState();
  const openDialog = useCallback((type, props) => {
    open({
      ...DIALOGS[type],
      ...props
    });
  }, [open]);
  return {
    openDialog,
    closeDialog
  };
}