import { QuerySubtaskIndicatorDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { FEATURE_TOGGLES, useVariation } from '@clubhouse/feature-toggles';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { DEFAULT_SUB_TASK_PARENT_CHIP_WIDTH } from '@clubhouse/shared/constants';
import { StorySubtaskIndicator } from 'components/stories/StorySubtasks/StorySubtaskIndicator';
import { getStorySubtaskIndicatorState } from 'components/stories/StorySubtasks/utils';
import { useQuery } from 'gql';
import { useState } from 'react';
import { TableNameTextContainer } from './TableNameText';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const QUERY_SUBTASK_INDICATOR = QuerySubtaskIndicatorDocument;
export function TableNameTextWithSubtaskIndicator({
  text = '-',
  storyGlobalId
}) {
  const {
    value: isSubtasksEnabled
  } = useVariation(FEATURE_TOGGLES.ENABLE_SUBTASKS);
  const [subtaskParentChipWidth, setSubtaskParentChipWidth] = useState(DEFAULT_SUB_TASK_PARENT_CHIP_WIDTH);
  const {
    data
  } = useQuery(QUERY_SUBTASK_INDICATOR, {
    variables: {
      storyId: storyGlobalId
    },
    skip: !isSubtasksEnabled
  });
  const storySubtaskIndicatorState = data?.node?.__typename === 'Story' ? getStorySubtaskIndicatorState(data.node) : null;
  const showSubtaskIndicator = Boolean(isSubtasksEnabled) && Boolean(storySubtaskIndicatorState);
  const addParentClass = showSubtaskIndicator && storySubtaskIndicatorState && storySubtaskIndicatorState.kind === 'subtask-parent' && storySubtaskIndicatorState.subtasks.length > 0;
  const addSubtaskClass = showSubtaskIndicator && storySubtaskIndicatorState?.kind === 'subtask';
  let subtaskIndicatorClass = '';
  if (showSubtaskIndicator && addParentClass) {
    subtaskIndicatorClass = 'table-sub-task-parent-name-text';
  }
  if (showSubtaskIndicator && addSubtaskClass) {
    subtaskIndicatorClass = 'table-sub-task-name-text';
  }
  return _jsxs(TableNameTextContainer, {
    subtaskParentChipWidth: subtaskParentChipWidth,
    children: [showSubtaskIndicator && _jsx(StorySubtaskIndicator, {
      storySubtaskIndicatorState: storySubtaskIndicatorState,
      setParentChipWidth: setSubtaskParentChipWidth
    }), _jsx(Text, {
      textAlign: "left",
      lineClamp: 2,
      bold: true,
      className: subtaskIndicatorClass,
      children: _jsx(Emojify, {
        children: text === '' ? '-' : text
      })
    })]
  });
}
TableNameTextWithSubtaskIndicator.displayName = "TableNameTextWithSubtaskIndicator";