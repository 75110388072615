import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import memoize from 'lodash/memoize';
export const getCommandBar = memoize(() => withEventHandler(window.CommandBar));
window.getCommandBar = getCommandBar;
function withEventHandler(CommandBar) {
  let eventHandlers = [];
  const removeEventHandler = fn => {
    if (!eventHandlers.includes(fn)) return;
    eventHandlers = eventHandlers.filter(handler => fn !== handler);
  };
  const addEventHandler = async fn => {
    if (eventHandlers.includes(fn)) return;
    eventHandlers = [...eventHandlers, fn];
  };
  CommandBar.addEventHandler((...args) => {
    eventHandlers.forEach(handler => handler.call(null, ...args));
  });
  return new Proxy({
    addEventHandler,
    removeEventHandler,
    addEventHandlers: fns => fns.forEach(addEventHandler),
    removeEventHandlers: fns => fns.forEach(removeEventHandler)
  }, {
    get: (target, prop) => (prop in target ? target : CommandBar)[prop]
  });
}