import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
export function getSubtasksForOptimisticResponse(subtasks, targetIndex, sourceIndex, closestEdgeOfTarget) {
  if (targetIndex === -1 || sourceIndex === -1 || !closestEdgeOfTarget) return subtasks;
  const newSubtasks = subtasks.slice();
  if (targetIndex < sourceIndex) {
    // If the drop target sits above the item being dragged.

    const repositionedItem = newSubtasks.splice(sourceIndex, 1);
    const indexToInsertAt = closestEdgeOfTarget === 'bottom' ? targetIndex + 1 : targetIndex;
    newSubtasks.splice(indexToInsertAt, 0, repositionedItem[0]);
  }
  if (targetIndex > sourceIndex) {
    // The drop target sits below the item being dragged.

    const repositionedItem = newSubtasks[sourceIndex];
    const indexToInsertAt = closestEdgeOfTarget === 'top' ? targetIndex : targetIndex + 1;
    newSubtasks.splice(indexToInsertAt, 0, repositionedItem);
    newSubtasks.splice(sourceIndex, 1);
  }
  return newSubtasks.map(edge => ({
    ...edge,
    node: {
      __typename: edge.node.__typename,
      id: edge.node.id
    }
  }));
}