import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { UserCohortAdapterPermissionFragmentDoc } from "../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { UserCohort } from '@clubhouse/shared/components/UserCohort';
import { assertType } from 'types/utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserCohortAdapterPermissionFragment = UserCohortAdapterPermissionFragmentDoc;
export function convertPermissionStateToProfileState(state) {
  // This will throw a compile error when the enum and union are out of sync
  assertType(true);

  // We don't need to do anything here because values defined by the enum are the same as the union,
  // which have been asserted at compile time by the above assertion.
  return state;
}
export function convertProfileStateToPermissionState(state) {
  // This will throw a compile error when the enum and union are out of sync
  assertType(true);

  // We don't need to do anything here because values defined by the enum are the same as the union,
  // which have been asserted at compile time by the above assertion.
  return state;
}

/**
 * This adapter is used to convert the Data Layer's Permission type to a Profile type so
 * we can use `<UserCohort>` with Data Layer.
 */
export function UserCohortAdapter(props) {
  const users = props.users.map(user => {
    return {
      entity_type: 'profile',
      id: user.publicId,
      name: user.displayName,
      mention_name: user.mentionName,
      mentionName: user.mentionName,
      email_address: user.email || undefined,
      display_icon: {
        url: user?.displayIcon?.thumbnailUrl ?? ''
      },
      state: convertPermissionStateToProfileState(user.state)
    };
  });
  return _jsx(UserCohort, {
    ...props,
    users: users
  });
}
UserCohortAdapter.displayName = "UserCohortAdapter";