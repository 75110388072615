import { MutateStoryIterationDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { QueryStoryIterationSelectDocument } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { IterationFieldFragmentFragmentDoc } from "../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { Center } from '@clubhouse/shared/components/Center';
import { SkeletonRectangle } from '@clubhouse/shared/components/SkeletonRectangle';
import { Nouns } from '@clubhouse/shared/constants';
import { GroupedOptionsFieldSingleSelection } from 'components/gql/GroupedOptionsField';
import { IterationSelectTarget } from 'components/gql/iteration/IterationSelectTarget';
import { getSection } from 'components/gql/iteration/utils/sections';
import { useFieldOptionsQuery } from 'components/gql/stories/table/fields/useFieldOptionsQuery';
import { createOptimisticStoryMutationResponse } from 'components/gql/utils/mutation';
import { useMutation } from 'gql';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const IterationFieldFragment = IterationFieldFragmentFragmentDoc;
const QUERY_ITERATION_SELECT = QueryStoryIterationSelectDocument;
const MUTATE_ITERATION_FIELD = MutateStoryIterationDocument;
export const IterationFieldComponent = ({
  entity: {
    id: storyId,
    iteration
  }
}) => {
  const fetchIterations = useFieldOptionsQuery({
    query: QUERY_ITERATION_SELECT,
    storyId,
    includeCurrentPermissionId: false
  });
  const [updateIteration] = useMutation(MUTATE_ITERATION_FIELD);
  const handleChange = useCallback(async iteration => {
    await updateIteration({
      variables: {
        storyId,
        input: {
          iteration
        }
      },
      optimisticResponse: createOptimisticStoryMutationResponse(storyId, {
        iteration: iteration ? {
          __typename: 'Iteration',
          id: iteration
        } : null
      })
    });
  }, [updateIteration, storyId]);
  return _jsx(GroupedOptionsFieldSingleSelection, {
    selectedEntity: iteration,
    fetchOptions: fetchIterations,
    getSection: getSection,
    onChange: handleChange,
    TargetComponent: IterationSelectTarget,
    unit: Nouns.Iteration
  });
};
IterationFieldComponent.displayName = "IterationFieldComponent";
IterationFieldComponent.displayName = 'IterationFieldComponent';
export const IterationField = () => ({
  name: 'iterationIds',
  displayName: 'Iteration',
  sort: 'iteration',
  LoadingComponent: ({
    animate
  }) => _jsx(Center, {
    children: _jsx(SkeletonRectangle, {
      animate: animate
    })
  }),
  Component: IterationFieldComponent,
  width: 240
});